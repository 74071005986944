@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-banners {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #eee;

  > ul {
    display: none;
  }

  &__controller {
    margin-top: 1rem;
    @include mix.mq-up(xl) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .swiper-pagination {
    position: static;
    @include mix.mq-up(xl) {
      width: auto;
      margin: 0 1rem;
    }
  }

  .swiper-pagination span {
    background: var.$primary;
  }
  
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
      position: static;
      margin-top: 0;
      width: .75rem;
      height: 1.5rem;
    }
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    color: var.$primary;
    font-size: 1.5rem;
  }
}