@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

#topcontrol {
  bottom: 4rem !important;
  z-index: 30;
  height: 3rem;
  padding: 0 1rem;
  background-color: var(--primary-color);
  color: white;
  line-height: 3rem;
  @include mix.mq-up(xl) {
    bottom: .5rem !important;
  }

  .icon-arrow {
    margin-right: .5rem;
    transform: rotate(45deg);
  }
}