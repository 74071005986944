@use "~/src/scss/base/mixins" as mix;

$color-orange: #ff643c;
$color-blue: #009eb8;

.ms2022-personal-apply-investment {
  @include mix.m-plus-1p("R");

  #main {
    margin-top: -1rem;
    @include mix.mq-up(xl) {
      margin-top: -3rem;
    }
  }

  #main > section:not(:last-child) {
    margin-bottom: 3rem;
    @include mix.mq-up(xl) {
      margin-bottom: 6rem;
    }
  }
}

.ms2022-investment-header {
  overflow-x: hidden;
  margin: 0 -1rem;
  @include mix.mq-up(md) {
    overflow-x: visible;
  }

  &__container-fluid {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-bottom: 1rem;
    padding: 1rem 0;
    background: url("/ms2022-assets/images/personal/apply/investment/hero-wave.svg") no-repeat center / cover;
    @include mix.mq-up(md) {
      width: calc(100vw - 15px);
      height: 45rem;
      margin-left: calc(50% - 50vw + (15px / 2));
      padding: 3rem 0;
    }
    @include mix.mq-up(lg) {
      height: 38rem;
    }
  }

  &__inner {
    position: relative;
    height: 100%;
  }
  
  &__heading {
    position: relative;
    z-index: 1;
    width: 90%;
    max-width: 24rem;
    margin-bottom: 1rem;
    @include mix.mq-up(md) {
      margin-bottom: 2rem;
    }
  }

  &__text {
    position: relative;
    z-index: 1;
    @include mix.m-plus-1p("M");
    font-size: 1.4rem;
    line-height: 1.3;
    @include mix.mq-up(md) {
      font-size: 2rem;
    }
  }

  &__text b {
    @include mix.m-plus-1p("B");
  }

  &__image {
    @include mix.mq-up(md) {
      position: absolute;
      z-index: 0;
      right: 0;
      left: 1rem;
      top: 0;
      bottom: -2rem;
    }
  }

  &__image > img {
    object-fit: contain;
    object-position: right bottom;
    width: 100%;
    height: 100%;
  }
}

.ms2022-investment-navigation {
  --fluid-contents-secondary-color: #e6e6e6;

  &__navigation {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include mix.mq-up(sm) {
      flex-direction: row;
      gap: 1.5rem;
    }
    @include mix.mq-up(xl) {
      gap: 3rem;
      height: 13rem;
    }
  }

  &__nav {
    flex: 1;
    display: flex;
    min-height: 8rem;
    border-radius: 1rem;
    text-decoration: none;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .5);
    transition: all .2s;
    @include mix.mq-up(sm) {
      border-radius: 2rem;
    }
    @include mix.mq-up(xl) {
      min-height: auto;
    }
  }

  &__nav[href="#introduction"] {
    flex-direction: row-reverse;
    @include mix.mq-up(sm) {
      flex-direction: row;
    }
  }

  &__nav:hover,
  &__nav:focus {
    box-shadow: none;
    transform: translateY(2px);
  }

  &__image {
    position: relative;
    min-width: 8rem;
    background-color: white;
    border-radius: 1rem 0 0 1rem;
    @include mix.mq-up(lg) {
      border-radius: 2rem 0 0 2rem;
    }
    @include mix.mq-up(xl) {
      flex: 1;
      border-radius: 2rem 0 0 2rem;
    }
  }

  &__nav[href="#introduction"] > .ms2022-investment-navigation__image {
    border-radius: 0 1rem 1rem 0;
    @include mix.mq-up(sm) {
      border-radius: 2rem 0 0 2rem;
    }
  }

  &__image > img {
    position: absolute;
    left: 0;
    bottom: 1rem;
    width: calc(100% - 1rem);
    max-width: none;
  }

  &__nav:hover > .ms2022-investment-navigation__image > img,
  &__nav:focus > .ms2022-investment-navigation__image > img {
    opacity: 1;
  }

  &__nav[href="#introduction"] > .ms2022-investment-navigation__image > img {
    min-width: calc(100% + 1rem);
    @include mix.mq-up(md) {
      min-width: calc(100% + 2rem);
    }
  }
  
  &__title {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: $color-orange;
    border-radius: 0 1rem 1rem 0;
    color: white;
    @include mix.m-plus-1p("M");
    font-size: 1.4rem;
    line-height: 1.3;
    @include mix.mq-up(lg) {
      font-size: 2rem;
      padding: 2rem;
      border-radius: 0 2rem 2rem 0;
    }
    @include mix.mq-up(xl) {
      flex: none;
      width: 55%;
    }
  }

  &__title > br {
    display: none;
    @include mix.mq-up(md) {
      display: block;
    }
  }

  &__nav[href="#introduction"] .ms2022-investment-navigation__title {
    background-color: $color-blue;
    border-radius: 1rem 0 0 1rem;
    @include mix.mq-up(sm) {
      border-radius: 0 2rem 2rem 0;
    }
  }
}

.ms2022-investment-banner {
  --swiper-pagination-color: #0094ab;

  .swiper-pagination {
    position: static;
    margin-top: 1rem;
  }
}

.ms2022-investment-nisa {
  position: relative;
  padding: 2rem 1.5rem;
  border: 1px solid black;
  @include mix.mq-up(xl) {
    width: 56rem;
    padding: 2rem 3rem;
    margin: auto;
  }

  &__heading {
    margin-bottom: 1rem;
    color: #1d2087;
    font-size: 2rem;
    @include mix.m-plus-1p("DB");
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 2.5rem;
    }
  }

  &__image {
    width: calc(237px * .5);
    margin: 0 auto;
    @include mix.mq-up(xl) {
      position: absolute;
      left: -4rem;
      bottom: -1px;
      width: 237px;
      margin: auto;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: #1d2087;
    color: white;
    @include mix.m-plus-1p("DB");
    font-size: 1.5rem;
    text-align: center;
    @include mix.mq-up(xl) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 10rem;
      margin-bottom: 0;
      padding: 2rem 2rem 2rem 8rem;
      font-size: 1.75rem;
      text-align: left;
    }
  }

  &__box > p {
    font-feature-settings: "palt";
  }

  &__box br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__link {
    padding: .5rem 1rem;
    background-color: white;
    color: #1d2087;
    @include mix.m-plus-1p("DB");
    font-size: 1.5rem;
    font-feature-settings: "palt";
    text-decoration: none;
    transition: .2s;
    @include mix.mq-up(xl) {
      font-size: 2rem;
    }
  }

  &__link:visited {
    color: #1d2087;
  }

  &__link:hover {
    background-color: $color-orange;
    color: white;
  }
}

.ms2022-investment-fluid-contents {
  --fluid-contents-primary-color: black;
  --fluid-contents-secondary-color: lightgray;
  
  width: 100vw;
  margin-left: calc(50% - 50vw);
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: var(--fluid-contents-secondary-color);
  @include mix.mq-up(xl) {
    width: calc(100vw - 15px);
    margin-left: calc(50% - 50vw + (15px / 2));
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &__heading {
    position: relative;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    color: var(--fluid-contents-primary-color);
    @include mix.m-plus-1p("DB");
    font-size: 2rem;
    line-height: 1.3;
    text-align: center;
    @include mix.mq-up(xl) {
      margin-bottom: 4rem;
      font-size: 3rem;
    }
  }

  &__heading::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 10rem;
    height: 3px;
    background-color: #ccc;
    transform: translateX(-50%);
    @include mix.mq-up(xl) {
      width: 16rem;
    }
  }

  &__heading::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 5rem;
    height: 3px;
    background-color: var(--fluid-contents-primary-color);
    transform: translateX(-50%);
    @include mix.mq-up(xl) {
      width: 8rem;
    }
  }
}

.ms2022-investment-internet-service {
  --fluid-contents-primary-color: #00af95;
  --fluid-contents-secondary-color: #e5f7f4;

  &__inner {
    position: relative;
    padding-bottom: 0;
  }

  &__cloud {
    position: absolute;
    bottom: 100%;
    right: 25%;
    width: calc(128px * .7);
    @include mix.mq-up(xl) {
      width: 128px;
    }
  }

  &__image {
    width: calc(191px * .5);
    margin: 0 auto;
    @include mix.mq-up(xl) {
      position: absolute;
      left: 10rem;
      bottom: -4rem;
      width: 191px;
      margin: auto;
    }
  }

  &__features {
    display: grid;
    gap: 3rem;
    margin-bottom: 3rem;
    @include mix.mq-up(md) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 4rem;
    }
  }

  &__feature {
    position: relative;
    flex: 1;
    display: grid;
    grid-template-rows: subgrid;
    grid-row: span 2;
    gap: 1.5rem;
    padding: 4rem 2rem 2rem;
    border: 5px solid #00af95;
    border-radius: 2rem;
    background-color: #fffbe1;
    text-align: center;
    @include mix.mq-up(xl) {
      padding: 2rem 1rem 1rem;
    }
  }

  &__merit {
    position: absolute;
    top: -2rem;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    padding-top: 1%;
    border: 5px solid white;
    background-color: #ff643c;
    border-radius: 999px;
    color: white;
    @include mix.m-plus-1p("DB");
    text-align: center;
    line-height: 1;
    translate: -50%;
    @include mix.mq-up(xl) {
      left: -2rem;
      width: 6rem;
      height: 6rem;
      translate: none;
    }
  }
  
  &__merit > span {
    font-size: 2em;
  }

  &__title {
    align-self: center;
    color: #00af95;
    @include mix.m-plus-1p("DB");
    font-size: 2rem;
    line-height: 1.1;
    @include mix.mq-up(xl) {
      font-size: 3rem;
    }
  }

  &__title > span {
    display: block;
    margin-bottom: .5rem;
    font-size: .7em;
  }

  &__commission {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    line-height: 1;
    @include mix.mq-up(xl) {
      flex-direction: row;
      gap: 1rem;
    }
  }
  
  &__commission > span {
    color: black;
    @include mix.m-plus-1p("R");
    font-size: max(18px, .35em);
    line-height: 1.3;
  }

  &__notice {
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 1.125rem;
    }
  }

  &__notice > span {
    font-size: .8em;
  }

  &__button > a {
    height: 5rem;
    margin: auto;
    background-color: #00af95;
    @include mix.mq-up(xl) {
      width: 30rem;
      height: 6rem;
      font-size: 1.5rem;
    }
  }
}

.ms2022-investment-find {
  --fluid-contents-primary-color: #ff643c;
  --fluid-contents-secondary-color: #ffefeb;

  &__inner {
    position: relative;
  }

  &__image {
    width: calc(304px * .5);
    margin: 0 auto;
    translate: 25%;
    @include mix.mq-up(xl) {
      position: absolute;
      right: 0;
      bottom: -4rem;
      width: 304px;
      margin: auto;
      translate: 0;
    }
  }

  &__button > a {
    height: 5rem;
    margin: auto;
    background-color: #ff643c;
    @include mix.mq-up(md) {
      width: 30rem;
      height: 6rem;
      font-size: 1.5rem;
    }
  }

  &__button > a > span {
    font-size: .6em;
  }

  &__button + p {
    margin-top: .5rem;
    text-align: center;
    @include mix.mq-up(md) {
      font-size: 1.125rem;
    }
  }
}

.ms2022-investment-introduction {
  --fluid-contents-primary-color: #009eb8;
  --fluid-contents-secondary-color: #e5f5f8;
  --primary-color: #009eb8;

  &__item {
    padding: 3rem 1.5rem;
    background-color: white;
  }
  
  &__inner {
    @include mix.mq-up(xl) {
      width: 800px;
      margin: auto;
    }
  }

  &__item + .ms2022-investment-introduction__item {
    margin-top: 3rem;
  }

  &__sub-heading {
    margin-bottom: 2rem;
    color: #009eb8;
    @include mix.m-plus-1p("DB");
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      margin-bottom: 3rem;
      font-size: 2rem;
    }
  }
}

.ms2022-investment-about {
  position: relative;

  &__desc {
    margin-bottom: 2rem;
    text-align: justify;
    line-height: 1.7;
    @include mix.mq-up(md) {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.25rem;
      text-align: center;
    }
  }

  &__desc br {
    display: none;
    @include mix.mq-up(md) {
      display: block;
    }
  }

  &__image1,
  &__image2 {
    @include mix.mq-up(xl) {
      position: absolute;
      top: 50%;
      translate: 0 -50%;
    }
  }

  &__image1 {
    width: calc(240px * .7);
    margin: auto;
    @include mix.mq-up(xl) {
      left: 0;
      width: 240px;
    }
  }

  &__image2 {
    display: none;
    @include mix.mq-up(xl) {
      right: 0;
      display: block;
      width: 333px;
    }
  }
}

.ms2022-investment-structure {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @include mix.mq-up(md) {
    display: block;
  }

  &__item {
    position: relative;
  }

  &__item:first-child {
    @include mix.mq-up(md) {
      height: 7rem;
    }
  }

  &__arrows {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
  }

  &__arrow-up,
  &__arrow-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 4rem;
    @include mix.mq-up(md) {
      position: absolute;
      top: 2.5rem;
      bottom: -.5rem;
      z-index: 1;
      height: auto;
    }
  }

  &__arrow-up {
    @include mix.mq-up(md) {
      left: 2rem;
    }
  }

  &__arrow-down {
    rotate: 180deg;
    @include mix.mq-up(md) {
      right: 2rem;
    }
  }

  &__arrow-up::before,
  &__arrow-down::before {
    content: "";
    width: 2rem;
    aspect-ratio: 1 / 1;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    @include mix.mq-up(md) {
      width: 3rem;
    }
  }
  
  &__arrow-up::after,
  &__arrow-down::after {
    content: "";
    width: 1rem;
    flex: 1;
    @include mix.mq-up(md) {
      width: 1.5rem;
    }
  }
  
  &__arrow-up::before,
  &__arrow-up::after {
    background-color: #f14b94;
  }

  &__arrow-down::before,
  &__arrow-down::after {
    background-color: #4a4d9f;
  }

  &__arrow-down::after {
    translate: 0 -1px
  }

  &__title {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 1.25rem;
    @include mix.m-plus-1p("M");
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(md) {
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }
  }

  &__title > br {
    @include mix.mq-up(lg) {
      display: none;
    }
  }

  &__title > span {
    color: #ff643c;
    @include mix.m-plus-1p("DB");
    font-size: .875rem;
    text-align: center;
    @include mix.mq-up(md) {
      position: absolute;
      top: 50%;
      right: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6rem;
      aspect-ratio: 1 / 1;
      margin: 0;
      background-color: #ff643c;
      border-radius: 999px;
      color: white;
      font-size: 1rem;
      translate: 0 -50%;
    }
  }

  &__title > span > br {
    display: none;
    @include mix.mq-up(md) {
      display: block;
    }
  }

  &__desc {
    margin-bottom: 1rem;
    font-size: 1rem;
    @include mix.mq-up(md) {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.25rem;
    }
    @include mix.mq-up(lg) {
      width: 33rem;
    }
  }

  &__desc:empty {
    display: none;
    @include mix.mq-up(md) {
      display: block;
    }
  }
}

.ms2022-investment-merit-risk {
  &__items {
    margin-bottom: 3rem;
    @include mix.mq-up(md) {
      display: flex;
    }
  }

  &__item {
    @include mix.mq-up(md) {
      flex: 1;
    }
  }
  
  &__item:first-child {
    margin-bottom: 2rem;
    @include mix.mq-up(md) {
      margin-bottom: 0;
      margin-right: 2rem;
      padding-right: 2rem;
      border-right: 1px solid var(--primary-color);
    }
  }

  &__title {
    margin-bottom: 2rem;
    padding: .5rem;
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    @include mix.mq-up(md) {
      font-size: 1.25rem;
    }
  }

  &__merit-list {
    padding-left: 1em;
    color: #ff643c;
    font-size: 1.25rem;
    @include mix.m-plus-1p("M");
    @include mix.mq-up(md) {
      font-size: 1.5rem;
    }
  }
  
  &__merit-list > li {
    list-style-type: disc;
  }

  &__notice {
    font-size: 1rem;
  }
}

.ms2022-investment-flow {
  position: relative;
  --font-size: 1.25rem;
  --half-leading: calc(((var(--font-size) * 1.5) - var(--font-size)) / 2);
  --correction-value: .8;
  --heading-middle-height: calc((var(--font-size) / 2 + var(--half-leading)) * var(--correction-value));
  @include mix.mq-up(xl) {
    --font-size: 1.5rem;
    --correction-value: 1;
  }

  &__inner {
    position: relative;
  }

  &__topics {
    position: relative;
    z-index: 1;
  }
  
  &__topic {
    position: relative;
    display: flex;
    gap: 1rem;
  }

  &__connecting-part {
    position: relative;
  }

  &__connecting-part::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(100% + 1px);
    z-index: 1;
    width: 1rem;
    height: 1rem;
    border: 3px solid #ff643c;
    border-radius: 999px;
    background-color: white;
    translate: -50% -50%;
    box-sizing: border-box;
  }

  &__branch {
    order: -1;
    display: flex;
    margin-bottom: calc(var(--half-leading) * 3 * -1);
    padding-top: var(--heading-middle-height);
  }

  &__main-branch {
    position: relative;
    z-index: 1;
    width: 3px;
    height: 100%;
    margin-left: .5rem;
    border-right: 3px solid #999;
    translate: -50% 0;
  }

  &__main-branch--inactive::before {
    display: none;
  }

  &__main-branch--end {
    height: 0;
  }

  &__sub-branch {
    position: relative;
    width: 2rem;
    height: 100%;
    border-right: 3px solid #999;
  }

  &__sub-branch--start {
    border-top: 3px solid #999;
  }

  &__sub-branch--end {
    border-bottom: 3px solid #999;
  }

  &__sub-branch--empty {
    border: none;
  }

  &__sub-branch--empty::before {
    display: none;
  }

  &__topic::before {
    content: "";
    flex: .1;
    height: 3px;
    margin-top: var(--heading-middle-height);
    background-color: #999;
    translate: 0 -50%;
  }

  &__texts {
    flex: 1;
    padding-bottom: 2rem;
  }

  &__texts a {
    color: $color-blue;
  }

  &__title {
    color: #009eb8;
    @include mix.m-plus-1p("M");
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__caption {
    margin-bottom: 2rem;
  }

  &__image {
    width: calc(313px * .7);
    margin: auto;
    @include mix.mq-up(md) {
      position: absolute;
      right: 0;
      top: 20%;
    }
    @include mix.mq-up(xl) {
      right: -20%;
      width: 313px;
    }
  }
}

.ms2022-investment-attention {
  --fluid-contents-secondary-color: #e8e9f3;
  
  h2 {
    margin-bottom: 2rem;
    color: #666;
    font-size: 1.5rem;
    @include mix.m-plus-1p("M");
  }

  ul {
    padding-left: 1.5em;
  }
  
  li {
    list-style-type: disc;
  }

  li:first-child {
    color: #1d2087;
  }
}