@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.linkNav {
  display: grid;
  gap: 2rem;
  @include mix.mq-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mix.mq-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  &::after {
    display: none;
  }
  
  li {
    margin: 0;
  }
}

.ms2022-card {
  position: relative;
  float: none;
  margin: auto;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 0;
  background: none;
  
  > a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    height: 100%;
    min-height: auto;
    padding: 4rem 1rem 1rem;
    color: inherit;
    background: none;
    @include mix.mq-up(xl) {
      padding: 5rem 1rem 1rem;
    }
  }

  &__icon {
    margin: 0 auto 1rem;
    color: var(--primary-color);
    text-align: center;
  }

  &__icon > i {
    color: var(--primary-color);
    font-size: 5rem;
  }

  // &__icon > img {
  //   height: 5rem;
  //   max-width: 9rem;
  // }

  // img {
  //   position: static;
  // }
  
  strong {
    display: flex;
    min-height: auto;
    margin-bottom: 0;
    padding: 0;
    border: none;
    color: var(--primary-color);
    font-size: 20px;
  }

  strong::before {
    content: "";
    width: .5rem;
    min-width: .5rem;
    height: .5rem;
    margin-top: .5em;
    margin-right: 1rem;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
  }

  span {
    margin-top: .5rem;
    padding: 0;
  }

  &__notice {
    position: absolute;
    top: 1rem;
    left: 1rem;
    padding: .25rem;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }

  &--noicon > a {
    padding-top: 1rem
  }

  &--noicon.ms2022-card--with-notice > a {
    padding-top: 5rem;
  }

  .icon-product-housing3 {
    display: inline-block;
    transform: scale(1.3);
    transform-origin: bottom;
  }
}