.ms2022-atm {
  .mb35 {
    text-align: center;

    img {
      height: auto;
    }
  }

  .linkBtn {
    a {
      min-height: auto;
    }

    span {
      font-size: .75em;
      font-weight: normal;
    }
  }

  .linkList {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 2rem;
    list-style: disc;

    li {
      display: list-item;
      margin-left: 0;
    }

    a {
      padding: 0;
      background: none;
    }

    .icon01 {
      margin: 0;
    }
  }

  .itemTable .bgColorG {
    background-color: white !important;
  }
}

[class^="ms2022-atm"] {
  iframe[src^="https://www.google.com/maps"] {
    width: 100%;
  }

  #google_map {
    display: none;
  }
}