.breadCrumb {
  margin-bottom: 2rem;
  
  li {
    margin-right: .5rem;
    font-weight: normal;
  }
  
  li a {
    padding-right: .75rem;
    color: black;
    text-decoration: none;
  }
}