@use "~/src/scss/base/mixins" as mix;

[class^="ms2022-personal-loan"] {
  .specialBox {
    padding: 0;
    background: none;
    
    .specialBoxIn {
      padding: 0;
    }
  }

  .scrollBox {
    font-size: 1rem;
  }
}

[class^="ms2022-personal-loan-free_smile_check"],
[class^="ms2022-personal-loan-free_quick_check"] {
  .check .linkList {
    display: flex;
    justify-content: center;
    padding-left: 0;
    @include mix.mq-up(xl) {
    }
  }

  .itemTable {
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    li {
      width: auto !important;
    }
  }
}