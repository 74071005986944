@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-others-faq {
  .questionList {
    padding-left: 1.25em;

    > li {
      margin-bottom: 1rem;
      padding: 0;
      background: none;
      list-style: disc;
    }

  }
  
  .qaBlock {
    dt, dd {
      padding: 0;
      background: none;
    }

    .h04 {
      margin-left: 0 !important;
    }
  }

  #identification {
    .ml20 {
      margin-left: 0 !important;
    }

    .fs18 + .mb10 {
      margin-bottom: 0 !important;
    }
  }
}