@use "~/src/scss/base/mixins" as mix;
@use "./variables" as local-var;

.ms2022-personal-loan-education {
  --lp-theme-color-primary: #{map-get(local-var.$education, "primary")};
  --lp-theme-color-primary-light: #{lighten(map-get(local-var.$education, "primary"), 10%)};
  --lp-theme-color-secondary: #{map-get(local-var.$education, "secondary")};
  --lp-theme-color-tertiary: #{map-get(local-var.$education, "tertiary")};
  --lp-theme-color-accent: #{local-var.$accent};
  --lp-theme-color-accent-light: #{lighten(local-var.$accent, 10%)};
}

.ms2022-lp-education-header {
  overflow-x: hidden;
  margin: 0 -1rem;
  @include mix.mq-up(xl) {
    overflow-x: visible;
  }

  &__container-fluid {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    @include mix.mq-up(xl) {
      width: calc(100vw - 15px);
      margin-left: calc(50% - 50vw + (15px / 2));
    }
  }
  
  &__signboard {
    position: relative;
    margin-bottom: 1rem;
    @include mix.mq-up(xl) {
      padding-top: 0;
    }
  }

  &__box {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include mix.mq-up(sm) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &__box:first-child {
    flex: 1;
    padding-bottom: 3rem;
    background-color: map-get(local-var.$education, primary);
  }

  &__box:nth-child(2) {
    background-color: darken(map-get(local-var.$education, primary), 10%);
  }

  &__box > * {
    position: relative;
    z-index: 1;
  }

  &__container {
    @include mix.mq-up(xl) {
      max-width: calc(1200px + 2rem);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__heading {
    width: 90%;
    max-width: 35rem;
    margin-bottom: 1rem;
    @include mix.mq-up(xl) {
      margin-bottom: 2rem;
    }
  }

  &__text {
    color: white;
    font-size: 1.125rem;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 2rem;
    }
  }

  &__text > br:last-of-type {
    @include mix.mq-up(xl) {
      display: none;
    }
  }

  &__text > b {
    @include mix.m-plus-1p("M");
    font-size: 1.2em;
  }

  &__kinri {
    width: 90%;
    max-width: 35rem;
    @include mix.mq-up(xl) {
      width: fit-content;
      max-width: none;
    }
  }

  &__image {
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__image > img {
    object-fit: contain;
    object-position: right;
    width: 100%;
    height: 100%;
    @include mix.mq-up(xl) {
      object-fit: cover;
    }
  }

  &__notice {
    color: var(--lp-theme-color-accent);
    font-size: .875rem;
    @include mix.m-plus-1p("DB");
    text-decoration: underline;
    @include mix.mq-up(xl) {
      font-size: 1rem;
    }
  }
}

.ms2022-lp-education-special-kinri {
  &__title {
    margin-bottom: 1rem;
    padding: .5rem;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    color: white;
    @include mix.m-plus-1p("DB");
    text-align: center;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, .5);
    @include mix.mq-up(xl) {
      font-size: 2.5rem;
      text-shadow: none;
    }
  }
  
  .ms2022-lp-kinri {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
    @include mix.mq-up(xl) {
      padding: 2rem;
    }
  }

  .ms2022-lp-kinri__caption {
    margin-bottom: 0;
    font-size: .75rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  .ms2022-lp-kinri__value {
    color: local-var.$accent;
    font-size: clamp(1rem, 14vw, 5rem);
    @include mix.mq-up(xl) {
      font-size: 9rem;
    }
  }
}

.ms2022-lp-education-summary {
  padding: 1rem;
  background-color: map-get(local-var.$education, secondary);
  @include mix.mq-up(xl) {
    padding: 2rem;
  }

  .ms2022-lp-feature__boxies {
    flex-direction: column;
    margin-bottom: 0;
    @include mix.mq-up(xl) {
      flex-direction: row;
    }
  }

  .ms2022-lp-feature__box {
    position: relative;
  }

  .ms2022-lp-feature__heading {
    @include mix.mq-up(xl) {
      flex: none;
    }
  }
  
  .ms2022-lp-feature__image {
    background-color: white;
    @include mix.mq-up(xl) {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .ms2022-lp-kinri {
    color: map-get(local-var.$education, primary);
  }

  &__kinri-container {
    max-width: 20rem;
    margin: auto;
    @include mix.mq-up(xl) {
      max-width: none;
    }
  }

  &__min-kinri,
  &__max-kinri {
    @include mix.mq-up(xl) {
      justify-content: flex-start;
    }
  }

  &__min-kinri {
    justify-content: flex-start;
  }
  
  &__max-kinri {
    justify-content: flex-end;
    margin-top: 0;
  }

  .ms2022-lp-kinri__value {
    font-size: clamp(1rem, 14vw, 5rem);
    @include mix.mq-up(xl) {
      font-size: 4rem;
    }
  }

  &__loan-amount,
  &__period {
    justify-content: center;
  }

  &__loan-amount > span:last-child {
    height: auto;
    font-size: .3em;
  }
  
  &__period > span:last-child {
    justify-content: center;
  }

  &__campaign {
    position: relative;
    width: fit-content;
    margin: .5rem auto 0;
    padding: .5rem 1rem;
    background-color: local-var.$accent;
    color: white;
    @include mix.m-plus-1p("DB");
    font-size: 1.125rem;
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      position: absolute;
      top: 100%;
      left: 50%;
      white-space: nowrap;
      transform: translateX(-50%);
    }
  }

  &__campaign::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    border: 1rem solid transparent;
    border-bottom-color: local-var.$accent;
    transform: translateX(-50%);
  }

  &__campaign a {
    color: white;
  }
}

.ms2022-lp-education-points {
  .ms2022-lp-points__heading {
    color: var(--lp-theme-color-primary);
  }

  .ms2022-lp-points__heading > span {
    background-color: #FF643C;
  }

  .ms2022-lp-circle {
    background-color: #FF643C;
  }

  .ms2022-lp-points__subject {
    color: var(--lp-theme-color-primary);
  }
}

.ms2022-lp-simulation-link {
  &__heading {
    margin-bottom: 1rem;
    color: var(--lp-theme-color-primary);
    @include mix.m-plus-1p("M");
    font-size: 1.25rem;
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 2.5rem;
    }
  }

  .ms2022-button {
    margin: auto;
    height: 4rem;
    @include mix.mq-up(xl) {
      width: 23rem;
    }
  }
}

.ms2022-lp-education-usecase {
  display: grid;
  gap: 2rem;
  padding: 1rem;
  background-color: var(--lp-theme-color-secondary);
  @include mix.mq-up(xl) {
    gap: 3rem;
    padding: 3rem;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    color: var(--lp-theme-color-primary);
    @include mix.m-plus-1p("DB");
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      gap: 1rem;
      font-size: 2rem;
    }
  }

  &__heading > span > span {
    font-size: 1.5em;
    line-height: 1;
  }

  &__heading br {
    @include mix.mq-up(xl) {
      display: none;
    }
  }

  &__block {
    padding: 1rem;
    background-color: white;
    @include mix.mq-up(xl) {
      padding: 3rem;
    }
  }
}

.ms2022-lp-education-feature {
  display: grid;
  gap: 2rem;
  padding: 0;
  background: none;
  @include mix.mq-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  &__text {
    margin-bottom: 2rem;
    @include mix.mq-up(xl) {
      font-size: 1.75rem;
    }
  }

  &__card {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    @include mix.mq-up(xl) {
      display: flex;
      flex-direction: column;
    }
  }

  &__body {
    padding: 1rem;
    background-color: white;
    @include mix.mq-up(xl) {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 0;
      padding-right: 0;
    }
  } 

  &__point {
    margin-bottom: 1rem;
    color: var(--lp-theme-color-primary);
    @include mix.m-plus-1p("B");
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.3;
  }

  &__point > br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__icon {
    height: 7rem;
    margin: 1rem auto;
    text-align: center;
  }

  &__icon > img {
    height: 100%;
  }

  &__usage {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    padding: .5rem;
    background-color: var(--lp-theme-color-primary);
    color: white;
    @include mix.m-plus-1p("DB");
    font-size: 1.25rem;
    text-align: center;
    @include mix.mq-up(xl) {
      height: 4rem;
    }
  }
}

.ms2022-lp-education-payback {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @include mix.mq-up(xl) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__whatis-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include mix.mq-up(xl) {
      grid-area: 1 / span 2;
      flex-direction: row;
    }
  }

  &__whatis {
    padding: 1rem;
    border: 3px solid #FF643C;
    @include mix.mq-up(xl) {
      flex: 1;
    }
  }
  
  &__whatis > h3 {
    margin-bottom: .5rem;
    color: #FF643C;
    font-size: 1rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__whatis > p {
    font-size: .75rem;
    @include mix.mq-up(xl) {
      font-size: 1.125rem;
    }
  }

  &__image {
    @include mix.mq-up(xl) {
      grid-area: 3 / 1;
    }
  }

  &__image > h3 {
    color: var(--lp-theme-color-primary);
    @include mix.m-plus-1p("DB");
    font-size: 1rem;
    @include mix.mq-up(xl) {
      font-size: 1.125rem;
    }
  }

  &__image > img {
    width: 100%;
  }
  
  .ms2022-lp-education-table {
    @include mix.mq-up(xl) {
      grid-area: 2 / span 2;
    }
  }

  &__description {
    text-align: justify;
    @include mix.mq-up(xl) {
      grid-area: 3 / 2;
    }
  }

  &__description > h4 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__description b {
    color: var(--lp-theme-color-accent);
    font-size: 1.2em;
  }

  &__description > p {
    font-size: .875rem;
    @include mix.mq-up(xl) {
      font-size: 1.125rem;
    }
  }

  &__description br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }
}

.ms2022-lp-education-flow {
  .ms2022-lp-step {
    padding: 0;
    border: none;
  }

  .ms2022-lp-step__arrow {
    @include mix.mq-up(xl) {
      width: 2.5rem;
    }
  }
  
  &__guide {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid;
    @include mix.mq-up(xl) {
      padding: 2rem;
    }
  }
  
  &__guide > h4 {
    margin-bottom: .5rem;
    font-size: 1.125rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }
  
  &__guide > p {
    margin-bottom: 1rem;
    font-size: .875rem;
    @include mix.mq-up(xl) {
      font-size: 1.125rem;
    }
  }

  .ms2022-button {
    @include mix.mq-up(xl) {
      width: 23rem;
      height: 4rem;
      margin: auto;
    }
  }
}

.ms2022-lp-education-table {
  position: relative;
  display: grid;
  @include mix.mq-up(xl) {
    grid-template-columns: auto 1fr 1fr 1fr;
  }

  // &::before {
  //   content: "";
  //   position: absolute;
  //   left: var(--lp-education-table-focus-left);
  //   top: var(--lp-education-table-focus-top);
  //   width: var(--lp-education-table-focus-width);
  //   height: var(--lp-education-table-focus-height);
  //   border: 4px solid var(--lp-theme-color-accent);
  // }
  
  > div {
    margin: 0 -1px -1px 0;
    padding: .5rem;
    border: 1px solid var(--lp-theme-color-primary);
    font-size: .875rem;
    @include mix.mq-up(xl) {
      font-size: 1rem;
    }
  }

  $count: 0;
  @for $i from 1 to 5 {
    @for $ii from 1 to 7 {
      $count: $count + 1;
      > div:nth-child(#{$count}) {
        @if $count >= 7 and $count <= 12 {
          border-left: 4px solid var(--lp-theme-color-accent) !important;
          border-right: 4px solid var(--lp-theme-color-accent) !important;
        }
        @if $count == 7 {
          border-top: 4px solid var(--lp-theme-color-accent);
        }
        @if $count == 12 {
          border-bottom: 4px solid var(--lp-theme-color-accent);
        }
        @include mix.mq-up(xl) {
          grid-area: #{$ii} / #{$i};
          @if $count >= 1 and $count <= 6 {
            border-right: none;
          }
          @if $count >= 13 and $count <= 18 {
            border-left: none;
          }
          @if $count == 13 {
            margin-left: 1px;
          }
        }
      }
    }
  }

  > div[data-title]::before {
    content: attr(data-title);
    display: block;
    color: var(--lp-theme-color-primary);
    @include mix.mq-up(xl) {
      display: none;
    }
  }
  
  &__head {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--lp-theme-color-primary);
    color: white;
    font-size: 1.125rem !important;
    text-align: center;
  }
  
  &__head:not(.ms2022-lp-education-table__head--first) {
    @include mix.mq-up(xl) {
      border-left-color: white !important;
    }
  }

  &__head--first {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__head.ms2022-lp-education-table__focus-start {
    font-size: 1.5rem !important;
  }

  &__subhead {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
      background-color: var(--lp-theme-color-secondary);
    }
  }

  > div:not(.ms2022-lp-education-table__subhead) + .ms2022-lp-education-table__head {
    margin-top: 2rem;
    @include mix.mq-up(xl) {
      margin-top: 0;
    }
  }
}

.ms2022-lp-education-qa {
  @include mix.mq-up(xl) {
    max-width: 1000px;
    margin: auto;
  }

  &__list {
    display: grid;
    gap: 2rem;
  }

  .ms2022-lp-accordion__bar {
    border-radius: .25rem;
    background-color: white;
    border-color: var(--lp-theme-color-primary);
    color: var(--lp-theme-color-primary);
    font-size: 1rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  .ms2022-lp-accordion__bar.is-open {
    border-radius: .25rem .25rem 0 0;
  }

  .ms2022-lp-accordion__body {
    border-color: var(--lp-theme-color-primary);
  }

  .ms2022-lp-accordion__bar > svg {
    fill: var(--lp-theme-color-primary);
  }

  .ms2022-lp-accordion__bar span,
  .ms2022-lp-accordion__body span {
    font-size: .875rem;
  }
}

.ms2022-lp-education-point3 {
  display: flex;
  align-items: center;

  > img {
    height: 80%;
  }
}

.ms2022-lp-education-application {
  .ms2022-lp-sticky__button {
    flex-direction: column;
    @include mix.mq-up(xl) {
      height: 6rem;
    }
  }

  span {
    font-size: .8em;
  }
}

.ms2022-personal-loan-education .ms2022-lp-repeat__header {
  display: grid;

  @include mix.mq-up(xl) {
    display: flex;
  }
}