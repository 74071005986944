@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

%sitemap-list {
  padding: 0 1rem 0 2.5rem;
  @include mix.mq-up(xl) {
    padding: 0 0 0 1.5rem;
    font-size: .875rem;
  }
}

%sitemap-bar {
  position: relative;
  overflow: hidden;
  height: 3rem;
  padding : 0 1rem;
  background : var(--secondary-color);
  font-weight: bold;
  line-height: 3rem;
  cursor :pointer;
  @include mix.mq-up(xl) {
    display: none;
  }

  &::after {
    content: "\e903";
    position: absolute;
    top: 50%;
    right: 1rem;
    font-family: 'icomoon';
    font-size: .75rem;
    transform: translateY(-50%) rotate(90deg);
    @include mix.mq-up(xl) {
      display: none;
    }
  }

  &.is-show::after {
    transform: translateY(-50%) rotate(-90deg);
  }
}

.ms2022-sitemap-index {
  background: var(--secondary-color);
  @include mix.mq-up(xl) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  input {
    display: none;
  }

  a, a:visited {
    color: inherit;
    transition: color .2s;
  }

  a:hover {
    color: var(--primary-color);
  }
  
  &__inner {
    @include mix.mq-up(xl) {
      max-width: map-get(var.$breakpoints, xl);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__headline {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
      margin-bottom: 2rem;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }
  
  &__block {
    @include mix.mq-up(xl) {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      margin-bottom: 2rem;
    }
  }
  
  &__block:first-of-type {
    @include mix.mq-up(xl) {
      display: flex;
      padding-bottom: 2rem;
      border-bottom: 1px solid var(--primary-color);
    }
  }

  &__block:last-of-type {
    display: none;
    @include mix.mq-up(xl) {
      display: flex;
      margin-bottom: 0;
    }
  }

  &__block:first-of-type &__item {
    @include mix.mq-up(xl) {
      flex-basis: 20%;
    }
  }

  &__item {
    border-bottom: 1px solid #ccc;
    @include mix.mq-up(xl) {
      border: none;
    }
  }
  
  &__bar,
  &__bar--link {
    @extend %sitemap-bar;
  }

  &__bar--link::after {
    content: "＞";
  }

  &__menu {
    overflow: hidden;
    height: 0;
    transition: height .2s ease;
    background-color: white;
    @include mix.mq-up(xl) {
      height: auto;
      background-color: transparent;
    }
  }

  &__menu > *:last-child {
    margin-bottom: 1rem;
    @include mix.mq-up(xl) {
      margin-bottom: 0;
    }
  }

  &__subhead {
    margin-top: 1rem;
    padding: 0 1rem;
    font-weight: bold;
    @include mix.mq-up(xl) {
      padding: 0;
      font-size: .875rem;
    }
  }
  
  &__subhead:first-child {
    @include mix.mq-up(xl) {
      font-size: 1rem;
      margin-top: 0;
    }
  }

  &__subhead > a::before {
    display: none;
    @include mix.mq-up(xl) {
      content: "＞";
      display: inline-block;
    }
  }

  &__subhead > a {
    display: flex;
    align-items: center;
  }

  &__subhead > a > span:first-child {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__subhead > a > span:last-child {
    @include mix.mq-up(xl) {
      display: none;
    }
  }
  
  &__list {
    @extend %sitemap-list;
  }
  
  &__list > li {
    list-style: disc;
  }
  
  &__list--arrow {
    @extend %sitemap-list;
    @include mix.mq-up(xl) {
      margin-left: 1em;
      padding: 0;
      text-indent: -1em;
    }
  }
  
  &__list--arrow > li::before {
    content: "＞";
  }

  &__major-classification {
    display: none;
    font-size: 1.125rem;
    font-weight: bold;
    @include mix.mq-up(xl) {
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding-top: 3rem;
      border-top: 1px solid var(--primary-color);
      font-size: 1.25rem;
    }
  }

  &__major-classification li {
    flex: 1;
    text-align: center;
  }

  &__block:nth-of-type(2) {
    background-color: white;
    @include mix.mq-up(xl) {
      background-color: transparent;
    }

    .ms2022-sitemap-index__list--arrow {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      padding: 1rem;
      font-size: .875rem;
      @include mix.mq-up(xl) {
        display: block;
        padding: 0;
      }
    }
  }
}