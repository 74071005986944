@use "~/src/scss/base/mixins" as mix;

.blankBtn {
  background: none;
  
  > a {
    @include mix.button($blank: true);
    height: 100%;
  }

  .fs14 {
    font-size: 1em !important;
  }
}