@use "~/src/scss/base/mixins" as mix;

.ms2022-cash {
  .number {
    padding-left: 1.5rem;
  }

  .number span {
    display: block;
  }

  .number > li:first-child {
    margin-bottom: 1rem;
  }

  .number p:first-child {
    margin-bottom: .5rem;
    color: #dd0033;
    font-weight: bold;
  }

  .number a {
    display: block;
    margin-top: .5rem;
  }

  .borderSec {
    display: inline-block;
    padding: 1rem;
    border: 1px solid #ccc;
  }

  .borderSec a {
    display: block;
    margin-bottom: 1rem;
  }

  .emSec {
    margin-bottom: 1rem;
  }

  &__number {
    display: block;
    color: red;
    font-size: 1.5rem;
    @include mix.mq-up(xl) {
      font-size: 2rem;
    }
  }
}