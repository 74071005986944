.ms2022-_tesuryo {
  .block1indent {
    padding: 0;
    background: none !important;

    .ml20 {
      margin-left: 0 !important;
    }

    .anchorList:first-of-type {
      margin-bottom: 3rem;
    }
  }

  #main > div:nth-of-type(3),
  #main > div:nth-of-type(4) {
    > * {
      margin-bottom: 2rem !important;
    }

    > h3,
    > h4 {
      margin-bottom: 1rem !important;
    }
  }

  // td[style="background-color: #f8ece1;"] {
  //   background-color: white !important;
  // }
}