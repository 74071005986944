.ms2022-_kinri {
  .block1indent {
    padding: 0;
    background: none !important;

    .ml20 {
      margin-left: 0 !important;
    }

    .anchorList:first-of-type {
      margin-bottom: 3rem;
    }
  }
}