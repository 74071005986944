@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;
@use "./variables" as local-var;
@use "./components";

.ms2022-personal-loan-carlife,
.ms2022-personal-loan-education,
.ms2022-personal-loan-mitoshin_cash {
  @include mix.m-plus-1p("R");
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
}

.ms2022-personal-loan-carlife ~ .ms2022-sticky,
.ms2022-personal-loan-education ~ .ms2022-sticky,
.ms2022-personal-loan-mitoshin_cash ~ .ms2022-sticky {
  display: none;
}

.ms2022-personal-loan-carlife ~ #topcontrol,
.ms2022-personal-loan-education ~ #topcontrol {
  bottom: 7rem !important;
  @include mix.mq-up(xl) {
    bottom: 9rem !important;
  }
}

.ms2022-personal-loan-mitoshin_cash ~ #topcontrol {
  bottom: 1rem !important;
  @include mix.mq-up(xl) {
    bottom: .5rem !important
  }
}

.ms2022-personal-loan-carlife ~ .copy,
.ms2022-personal-loan-education ~ .copy {
  margin-bottom: 3rem;
  @include mix.mq-up(xl) {
    margin-bottom: 8rem;
  }
}

.ms2022-personal-loan-carlife #main,
.ms2022-personal-loan-education #main,
.ms2022-personal-loan-mitoshin_cash #main {
  margin-top: -1rem;
  @include mix.mq-up(xl) {
    margin-top: -3rem;
  }
}

.ms2022-personal-loan-carlife #main > section:not(:last-child),
.ms2022-personal-loan-education #main > section:not(:last-child),
.ms2022-personal-loan-mitoshin_cash #main > section:not(:last-child) {
  margin-bottom: 3rem;
  @include mix.mq-up(xl) {
    margin-bottom: 6rem;
  }
}

@import "./carlife";
@import "./education";
@import "./castle";