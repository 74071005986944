@use "~/src/scss/base/mixins" as mix;
@use "./variables" as local-var;

.ms2022-personal-loan-carlife {
  --lp-theme-color-primary: #{map-get(local-var.$carlife, "primary")};
  --lp-theme-color-primary-light: #{lighten(map-get(local-var.$carlife, "primary"), 10%)};
  --lp-theme-color-secondary: #{map-get(local-var.$carlife, "secondary")};
  --lp-theme-color-tertiary: #{map-get(local-var.$carlife, "tertiary")};
  --lp-theme-color-accent: #{local-var.$accent};
  --lp-theme-color-accent-light: #{lighten(local-var.$accent, 10%)};
}

.ms2022-lp-carlife-header {
  overflow-x: hidden;
  margin: 0 -1rem;
  @include mix.mq-up(xl) {
    overflow-x: visible;
  }

  &__container-fluid {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    @include mix.mq-up(xl) {
      width: calc(100vw - 15px);
      margin-left: calc(50% - 50vw + (15px / 2));
    }
  }
  
  &__signboard {
    overflow: hidden;
    position: relative;
    margin-bottom: 1rem;
    @include mix.mq-up(xl) {
      padding-top: 0;
    }
  }

  &__box {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include mix.mq-up(sm) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &__box:first-child {
    flex: 1;
    padding-bottom: 3rem;
    background-color: map-get(local-var.$carlife, primary);
  }

  &__box:nth-child(2) {
    background-color: rgb(52,	99,	176);
  }

  &__box > * {
    position: relative;
    z-index: 1;
  }

  &__container {
    @include mix.mq-up(xl) {
      max-width: calc(1200px + 2rem);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__heading {
    width: 90%;
    max-width: 40rem;
    margin-bottom: 1rem;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, .2));
    @include mix.mq-up(xl) {
      margin-bottom: 2rem;
      filter: none;
    }
  }

  &__text {
    color: white;
    font-size: 1rem;
    line-height: 1.3;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, .4);
    @include mix.mq-up(xl) {
      font-size: 2rem;
      text-shadow: none;
    }
  }

  &__text > b {
    @include mix.m-plus-1p("M");
    font-size: 1.4em;
    @include mix.mq-up(xl) {
      font-size: 1.2em;
    }
  }

  &__text > b > br,
  &__text > br:nth-of-type(2),
  &__text > br:nth-of-type(4) {
    @include mix.mq-up(xl) {
      display: none;
    }
  }

  &__kinri {
    width: 90%;
    max-width: 40rem;
    @include mix.mq-up(xl) {
      width: fit-content;
      max-width: none;
    }
  }

  &__image {
    position: absolute;
    z-index: 0;
    right: -2rem;
    top: 0;
    bottom: 0;
    @include mix.mq-up(xl) {
      right: 0;
    }
  }

  &__image > img {
    object-fit: contain;
    object-position: right bottom;
    width: 100%;
    height: 100%;
    @include mix.mq-up(xl) {
      object-fit: cover;
    }
  }

  &__feature {
    margin-top: 1rem;
    color: white;
    @include mix.m-plus-1p("M");
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.3;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, .8);
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
      text-shadow: none;
    }
  }

  &__feature > br {
    @include mix.mq-up(xl) {
      display: none;
    }
  }
}

.ms2022-lp-carlife-special-kinri {
  border: 2px solid var(--lp-theme-color-accent);

  &__title {
    padding: .25rem .5rem;
    background-color: var(--lp-theme-color-accent);
    color: white;
    @include mix.m-plus-1p("DB");
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 2.5rem;
      text-shadow: none;
    }
  }
  
  .ms2022-lp-kinri {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, .8);
    @include mix.mq-up(xl) {
      padding: 2rem;
    }
  }

  .ms2022-lp-kinri__caption {
    margin-bottom: 0;
    font-size: .75rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  .ms2022-lp-kinri__value {
    color: var(--lp-theme-color-accent);
    font-size: clamp(1rem, 14vw, 5rem);
    @include mix.mq-up(xl) {
      font-size: 9rem;
    }
  }
}

.ms2022-lp-navigation {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 4rem);
  @include mix.mq-up(xl) {
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 7rem 7rem;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--lp-theme-color-tertiary);
    border-radius: .5rem;
    color: var(--lp-theme-color-tertiary) !important;
    font-size: 1rem;
    @include mix.m-plus-1p("M");
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: .2s;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__button:hover {
    background-color: var(--lp-theme-color-tertiary);
    color: white !important;
  }

  &__button--important {
    border: none;
    background-color: var(--lp-theme-color-accent);
    color: white !important;
  }

  &__button--important:hover {
    background-color: var(--lp-theme-color-accent-light);
  }
}

.ms2022-lp-plan {
  position: relative;
  overflow: hidden;
  margin: 0 -1rem;
  padding: 3rem 1rem 20rem;
  @include mix.mq-up(xl) {
    overflow: visible;
    margin: 0;
    padding-bottom: 25rem;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    width: 100vw ;
    height: 100%;
    background: url("/ms2022-assets/images/personal/loan/carlife/plan-bg@2x.jpg") no-repeat top / cover;
    transform: translateX(-50%);
    @include mix.mq-up(xl) {
      width: calc(100vw - 15px);
      background: url("/ms2022-assets/images/personal/loan/carlife/plan-bg.lg.jpg") no-repeat center 80% / cover;
    }
  }
  
  &__heading {
    margin-bottom: 2rem;
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("M");
    font-size: 1.75rem;
    text-align: center;
    line-height: 1.3;
    letter-spacing: .1em;
    @include mix.mq-up(xl) {
      font-size: 3rem;
    }
  }

  &__heading > img {
    display: block;
    width: 3rem;
    margin: 0 auto 1rem;
    @include mix.mq-up(xl) {
      width: 5rem;
    }
  }

  &__heading > span {
    position: relative;
    display: table;
    margin: auto;
    padding: 0 1rem;
    filter: drop-shadow(-1px -1px 0px #FFFFFF)
           drop-shadow(1px -1px 0px #FFFFFF)
           drop-shadow(1px 1px 0px #FFFFFF)
           drop-shadow(-1px 1px 0px #FFFFFF);
  }

  &__heading > span::before,
  &__heading > span::after {
    content: "";
    position: absolute;
    top: 10%;
    width: 3px;
    height: 90%;
    background-color: var(--lp-theme-color-tertiary);
    transform-origin: top;
  }
  
  &__heading > span::before {
    left: 0;
    transform: rotate(-20deg);
  }
  
  &__heading > span::after {
    right: 0;
    transform: rotate(20deg);
  }

  &__points {
    display: grid;
    gap: 2rem;
    @include mix.mq-up(xl) {
      grid-template-columns: 1fr 1fr;
      width: 90%;
      margin: auto;
    }
  }

  &__point {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 7rem 1rem 3rem;
    background-color: white;
    text-align: center;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  &__subhead {
    position: absolute;
    top: 1.5rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    padding: .5rem 1rem;
    border-radius: 0 .5rem .5rem 0;
    background-color: var(--lp-theme-color-primary);
    color: white;
    @include mix.m-plus-1p("M");
    font-size: 2rem;
    line-height: 1;
    @include mix.mq-up(xl) {
      font-size: 2.5rem;
      width: 13rem;
      height: 4rem;
    }
  }

  &__subhead > img {
    width: 1em;
  }

  &__subhead > span {
    @include mix.m-plus-1p("DB");
    font-size: .6em;
  }

  &__text {
    font-size: 1.5rem;
    line-height: 1.5;
    @include mix.mq-up(xl) {
      font-size: 2rem;
    }
  }
  
  &__text > span {
    color: var(--lp-theme-color-primary);
    @include mix.m-plus-1p("M");
    @include mix.mq-up(xl) {
      font-size: 1.3em;
    }
  }

  &__notices {
    margin-top: 1rem;
    padding-left: 1em;
    text-indent: -1em;
    text-align: left;
  }
}

.ms2022-lp-comparison {
  @include mix.mq-up(xl) {
    max-width: 1000px;
    margin: auto;
  }

  &__heading {
    margin-bottom: 1rem;
  }

  &__table {
    width: 100%;
    margin-bottom: .5rem;
    border-collapse: collapse;
  }

  &__table thead th {
    height: 3.5rem;
    padding: 0 .5rem;
    border-radius: 1rem 1rem 0 0;
    color: white;
    @include mix.m-plus-1p("M");
    font-size: .75rem;
    text-align: center;
    line-height: 1.2;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__table thead th:nth-child(2) {
    background-color: var(--lp-theme-color-primary);
  }

  &__table thead th:nth-child(3) {
    background-color: #B4B4B5;
    color: black;
  }

  &__table tbody th,
  &__table tbody td {
    padding: .5rem;
    border: none !important;
    border-top: 1px solid var(--lp-theme-color-primary) !important;
    border-bottom: 1px solid var(--lp-theme-color-primary) !important;
    @include mix.mq-up(xl) {
      padding: 1rem .5rem;
    }
  }

  &__table tbody td {
    vertical-align: top;
    @include mix.mq-up(xl) {
      vertical-align: middle;
    }
  }

  &__table tbody th {
    width: 4rem;
    padding: 0 .5rem;
    @include mix.m-plus-1p("M");
    @include mix.mq-up(xl) {
      width: auto;
      padding-left: .5rem;
      padding-right: 2rem;
    }
  }

  &__th {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: .75rem;
    @include mix.mq-up(xl) {
      justify-content: space-between;
      flex-wrap: nowrap;
      white-space: nowrap;
      width: auto;
      font-size: 1.25rem;
    }
  }

  &__table tbody td:first-of-type {
    background-color: var(--lp-theme-color-secondary);
  }

  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
    @include mix.mq-up(xl) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__icon {
    width: 1.5rem;
    margin: auto;
    @include mix.mq-up(xl) {
      width: 2.5rem;
    }
  }

  &__cell > div:last-child {
    flex: 1;
  }

  &__text {
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
      white-space: nowrap;
    }
  }

  &__text > b {
    color: var(--lp-theme-color-accent);
    @include mix.m-plus-1p("M");
    font-size: 1.2em;
  }

  &__table tbody td:last-child &__text > b {
    color: #888;
  }

  &__bold-red {
    color: var(--lp-theme-color-accent) !important;
  }

  &__bold-gray {
    color: #888 !important;
  }

  &__caption {
    font-size: .75rem;
    @include mix.mq-up(xl) {
      font-size: 1rem;
    }
  }
}

.ms2022-lp-simulation {
  @include mix.mq-up(xl) {
    max-width: 1000px;
    margin: auto;
  }

  &__heading {
    margin-bottom: 1rem;
  }

  &__container {
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 1.5rem 1rem 4.5rem;
    background-color: var(--lp-theme-color-secondary);
    @include mix.mq-up(xl) {
      margin-left: 0;
      margin-right: 0;
      padding: 2rem 2rem 5rem;
    }
  }

  &__comparison {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 1.25rem;
    line-height: 1.2;
    @include mix.mq-up(xl) {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
  }

  &__subhead {
    grid-column: span 2;
    padding: .5rem 1rem;
    color: white;
    background-color: var(--lp-theme-color-primary);
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 1em;
    }
  }

  &__subhead > br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__comparison > p {
    padding: 1rem;
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid var(--lp-theme-color-primary);
    @include mix.mq-up(xl) {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
      border-right: 1px solid var(--lp-theme-color-primary);
      font-size: 1em;
    }
  }

  &__comparison > p:first-of-type {
    border-right: 1px solid var(--lp-theme-color-primary);
  }

  &__comparison > p:last-child {
    grid-column: span 2;
  }

  &__arrow {
    margin: 2rem 0;
    color: var(--lp-theme-color-primary);
    font-size: 2rem;
    text-align: center;
    line-height: .6;
    @include mix.mq-up(xl) {
      margin: 1rem 0;
      font-size: 3rem;
    }
  }

  &__example {
    position: relative;
  }

  &__table {
    width: 100%;
    margin-bottom: .5rem;
    border-collapse: collapse;
    @include mix.m-plus-1p("M");
    @include mix.mq-up(xl) {
      font-size: 1.25rem;
    }
  }

  &__table thead {
    letter-spacing: .2em;
  }

  &__table thead th:not(:empty) {
    padding-bottom: 1rem;
    font-size: .75rem;
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 20px;
    }
  }

  &__table thead th > p {
    padding: .5rem 0;
    background-color: var(--lp-theme-color-primary);
    color: white;
  }

  &__table tbody th,
  &__table tbody td {
    border: none !important;
    border-top: 1px solid var(--lp-theme-color-primary) !important;
    border-bottom: 1px solid var(--lp-theme-color-primary) !important;
    text-align: center;
  }

  &__table tbody th {
    border-right: 1px solid var(--lp-theme-color-primary) !important;
    width: 3rem;
    font-size: 10px;
    @include mix.mq-up(xl) {
      width: auto;
      font-size: 1rem;
    }
  }

  &__table tbody td + td {
    border-left: 1px solid var(--lp-theme-color-primary) !important;
  }

  &__table tbody td:nth-of-type(2) {
    @include mix.mq-up(xl) {
      font-size: 1.8em;
    }
  }

  &__table tbody td:nth-of-type(3) {
    @include mix.mq-up(xl) {
      font-size: 1.8em;
    }
  }

  &__table tbody th > br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__our-bank {
    background-color: white;
    color: var(--lp-theme-color-accent);
    font-size: 18px;
    @include mix.mq-up(xl) {
      font-size: 22px;
    }
  }

  &__our-bank th,
  &__our-bank td {
    padding: .5rem;
    @include mix.mq-up(xl) {
      padding: 1rem;
    }
  }

  &__our-bank th {
    @include mix.mq-up(xl) {
      font-size: 1.25rem !important;
    }
  }

  &__our-bank .ms2022-lp-kinri {
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__dealer {
    @include mix.mq-up(xl) {
      font-size: 1.125rem;
    }
  }

  &__dealer + .ms2022-lp-simulation__dealer {
    margin-top: 2rem;
  }

  &__dealer th,
  &__dealer td {
    padding: .5rem 0;
    @include mix.mq-up(xl) {
      padding: .5rem 1rem;
    }
  }

  &__dealer th br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__case {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    font-size: .75rem;
    @include mix.mq-up(xl) {
      flex-direction: row;
      align-items: flex-end;
      gap: 0;
      font-size: 1.25rem;
    }
  }

  &__case > p {
    line-height: 1;
  }

  &__table tbody &__emphasis-top {
    border-top: 4px solid var(--lp-theme-color-accent) !important;
    border-left: 4px solid var(--lp-theme-color-accent) !important;
    border-right: 4px solid var(--lp-theme-color-accent) !important;
  }

  &__table tbody &__emphasis-bottom {
    position: relative;
    border: 4px solid var(--lp-theme-color-accent) !important;
    border-top: none !important;
  }

  &__table tbody &__emphasis-bottom::after {
    content: "";
    position: absolute;
    right: calc(50% - 1rem);
    top: calc(100% - 1rem + 5px);
    border: 1rem solid transparent;
    border-bottom-color: var(--lp-theme-color-accent);
  }

  &__balloon {
    position: absolute;
    top: calc(100% + 1rem);
    right: 0;
    min-width: 40%;
    padding: .5rem;
    background-color: var(--lp-theme-color-accent);
    color: white;
    @include mix.m-plus-1p("DB");
    text-align: center;
    @include mix.mq-up(xl) {
      width: 328px;
      padding: .5rem 1rem;
      min-width: auto;
      font-size: 1.5rem;
    }
  }

  &__balloon > span {
    font-size: 1.3em;
  }

  &__notice {
    font-size: .75rem;
    @include mix.mq-up(xl) {
      font-size: 1rem;
    }
  }

  .ms2022-scrollable-table {
    margin-bottom: 1rem;
  }

  .ms2022-lp-kinri {
    justify-content: center;
    gap: 0;
  }

  .ms2022-lp-kinri__value {
    justify-content: center;
    gap: 0;
    font-size: 1.5em;
    @include mix.mq-up(xl) {
      font-size: 2em;
    }
  }

  .ms2022-lp-kinri__value > span:first-child,
  .ms2022-lp-kinri__value > span:last-child {
    height: auto;
  }

  .ms2022-lp-kinri__value > span:first-child {
    font-size: .4em;
  }
}

.ms2022-lp-usage {
  @include mix.mq-up(xl) {
    max-width: 1000px;
    margin: auto;
  }

  &__heading {
    margin-bottom: 2rem;
    color: var(--lp-theme-color-primary);
    @include mix.m-plus-1p("M");
    text-align: center;
  }

  &__text {
    margin-bottom: 2rem;
    @include mix.mq-up(xl) {
      font-size: 1.75rem;
    }
  }

  &__usecase {
    display: grid;
    gap: 2rem;
    margin-bottom: 2rem;
    @include mix.mq-up(xl) {
      grid-template-columns: 1fr 2fr;
      margin-bottom: 3rem;
    }
  }

  &__usecase > * {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }

  &__subhead {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background-color: var(--lp-theme-color-primary);
    color: white;
    font-size: 1.25rem;
    text-align: center;
    @include mix.mq-up(xl) {
      height: 4rem;
      font-size: 1.5rem;
    }
  }

  &__body {
    padding: 1rem;
    border: 1px solid var(--lp-theme-color-primary);
    background-color: white;
  }

  &__dashboard {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem 3rem;
    margin: auto;
  }

  &__dashboard--other {
    @include mix.mq-up(xl) {
      width: 84%;
      margin: auto;
    }
  }

  &__dashboard figure {
    width: 100px;
    margin: 0;
    text-align: center;
  }

  &__dashboard figcaption {
    display: flex;
    justify-content: center;
    font-size: 1.125rem;
    white-space: nowrap;
  }
}

.ms2022-lp-overview {
  @include mix.mq-up(xl) {
    max-width: 1000px;
    margin: auto;
  }

  &__heading {
    margin-bottom: 2rem;
    color: var(--lp-theme-color-primary);
    @include mix.m-plus-1p("M");
    text-align: center;
  }

  &__general {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
    @include mix.mq-up(xl) {
      flex-direction: row;
    }
  }

  &__general > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include mix.mq-up(xl) {
      flex: 1;
    }
  }
}

.ms2022-personal-loan-carlife .ms2022-lp-visit__block:first-child {
  position: relative;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 2px solid var(--lp-theme-color-primary);

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 1.5rem solid transparent;
    border-bottom-color: var(--lp-theme-color-primary);
  }
}

.ms2022-personal-loan-carlife .ms2022-lp-repeat__header {
  display: grid;

  @include mix.mq-up(xl) {
    display: flex;
  }
}

.ms2022-personal-loan-carlife .ms2022-lp-repeat__overview {
  @include mix.mq-up(xl) {
    align-items: flex-start;
  }
}