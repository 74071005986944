@use "~/src/scss/base/mixins" as mix;

.pdfBtn {
  @include mix.button;
  border: none;
  font-size: 1rem;
  font-weight: normal;

  > a {
    padding: 0;
    background: none;
    color: white;
  }
}