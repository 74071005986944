@use "~/src/scss/base/mixins" as mix;

[class^="ms2022-corp-gain-loan"] {
  .specialBox {
    padding: 0;
    background: none;
    
    .specialBoxIn {
      padding: 0;
    }

    .alL {
      text-align: center !important;
    }
  }

  .scrollBox {
    font-size: 1rem;
  }

}

.ms2022-corp-gain-loan_quick_check {
  .itemTable ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.ms2022-corp-gain-loan_quick_error {
  .ms2022-linkbtns {
    @include mix.mq-up(xl) {
      width: 33.3%;
      margin: auto;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}