@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.itemTable {
  width: 100%;
  table-layout: fixed;
  border: none;
  @include mix.mq-up(lg) {
    table-layout: auto;
  }

  colgroup {
    display: none;
  }

  th {
    height: auto;
    background-image: none !important;
    background-color: var(--secondary-color);
    border: 1px solid #ccc;
  }
  
  th.bgColorF {
    background-color: #eee !important;
  }
  
  > tbody > tr > th {
    border: none;
    display: block;
    background-image: none !important;
    @include mix.mq-up(xl) {
      display: table-cell;
      border: 1px solid #ccc;
      white-space: nowrap;
    }
  }

  > tbody > tr > td {
    border: none;
    display: block;
    background-color: transparent;
    @include mix.mq-up(xl) {
      display: table-cell;
      border: 1px solid #ccc;
    }
  }

  img {
    max-width: none;
  }
}

.ms2022-scrollable-table:not(.ms2022-scrollable-table--onecol) {
  overflow-x: scroll;
  @include mix.mq-up(xl) {
    overflow-x: visible;
  }
  
  > table {
    table-layout: auto;
    white-space: nowrap;
    border-collapse: collapse;
    @include mix.mq-up(xl) {
      white-space: initial;
    }
  }
  
  > table > tbody > tr > th,
  > table > tbody > tr > td {
    display: table-cell;
    border: 1px solid #ccc;
  }
}