@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.branch {
  p {
    position: static;
    margin-bottom: 1rem;
    @include mix.mq-up(xl) {
      position: absolute;
      margin-bottom: 0;
    }
  }
}