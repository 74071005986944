@use "~/src/scss/base/variables" as var;

@mixin mq-up($point) {
  $value: map-get(var.$breakpoints, $point);
  @media (min-width: $value) {
    @content;
  }
}

@mixin mq-down($point) {
  $value: map-get(var.$breakpoints, $point);
  @media (max-width: $value) {
    @content;
  }
}

@mixin button(
  $defaultColor: (text: white, bg: var(--primary-color)),
  $hoverColor: null,
  $blank: false
) {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 3rem;
  padding: .5rem 2rem;
  border-radius: .25rem;
  background: map-get($defaultColor, bg);
  color: map-get($defaultColor, text);
  font-size: 1.125rem;
  text-decoration: none;
  transition: .2s;
  @include mq-up(xl) {
    font-size: 1.25rem;
  }

  &:visited {
    color: map-get($defaultColor, text);
  }

  &:hover {
    @if $hoverColor {
      background: map-get($hoverColor, bg);
      color: map-get($hoverColor, text);
    } @else {
      opacity: .7;
    }
  }
  
  &::after {
    position: absolute;
    left: .5rem;
    @if $blank != true {
      content: "";
      top: calc(50% - .25rem);
      width: .5rem;
      height: .5rem;
      border-top: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(45deg);
    } @else {
      content: "\f35d";
      top: 50%;
      width: auto;
      height: auto;
      border: none;
      font-family: 'Font Awesome 6 Free';
      font-weight: 900;
      font-size: .75em;
      transform: translateY(-50%);
    }
  }
}

@mixin m-plus-1p($weight) {
  font-family: "M PLUS 1p", sans-serif;
  font-style: normal;
  @if $weight == "R" {
    font-weight: 400;
  } @else if $weight == "M" {
    font-weight: 500;
  } @else if $weight == "D" {
    font-weight: 700;
  } @else if $weight == "DB" {
    font-weight: 800;
  }
}