@use "~/src/scss/base/mixins" as mix;

.ms2022-about-disclosure {
  &__contents {
    @include mix.mq-up(xl) {
      width: 60%;
      margin: auto;
    }
  
    .linkList {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 1.5rem;
      padding-left: 0;
      @include mix.mq-up(xl) {
      }
    }
    
    .linkList > li {
      list-style-position: inside;
    }

    p + .linkList{
      margin-top: 1rem;
    }
  }

  &__subtitle:nth-of-type(2) {
    margin-top: 2rem;
  }
}

.ms2022-about-disclosure-infomation {
  .linkList.ml25 {
    margin-left: 0 !important;
  }
}