@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-button {
  @include mix.button;  
}

.ms2022-button--outline {
  @include mix.button;
  border: 2px solid var(--primary-color);
  background-color: white;
  color: var(--primary-color);

  &::after {
    border-color: var(--primary-color);
  }

  &:visited {
    color: var(--primary-color);
  }
  
  &:hover {
    background-color: var(--primary-color);
    color: white;
    opacity: 1;
  }
  
  &:hover::after {
    border-color: white;
  }
}

/* .ms2022-button--white {
  border: 2px solid var(--primary-color);
  background-color: white;
  color: var(--primary-color);
} */

.btnColor {
  background: none;
  
  a {
    @include mix.button;
    color: white;
    background-image: none;
  }
}
