@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-sitemap {
  .noborder {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0;

    &:first-child {
      margin-bottom: 2rem;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem 1rem;
      padding: 2rem;
      background: var.$secondary;
      @include mix.mq-up(xl) {
        gap: 2rem;
      }
    }

    > div > div {
      padding: 0;
    }
    
    div {
      width: auto;
      border: none;
      box-shadow: none;
    }

    a {
      color: black;
    }

    .h03 > a::before,
    .h04 > a::before {
      content: ">";
      margin-right: .5rem;
    }

    .h03 {
      width: 100%;
      margin-top: 0 !important;
      margin-bottom: 0;
      @include mix.mq-up(xl) {
        grid-column: span 6;
      }
    }

    .h04 {
      margin-top: 0 !important;
      padding-bottom: 1rem;
    }
  }
}