@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-spring-board {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  @include mix.mq-up(xl) {
    gap: 0;
    grid-template-columns: repeat(6, 1fr);
  }
  
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.5rem;
    border: 1px solid var(--primary-color);
    color: black !important;
    text-align: center;
    text-decoration: none !important;
    transition: .2s;
    @include mix.mq-up(xl) {
      padding: 1.5rem 0.5rem;
    }
  }

  &__item:hover {
    background-color: var(--primary-color);
  }

  &__item:not(:last-child) {
    @include mix.mq-up(xl) {
      margin-right: -1px;
    }
  }
  
  &__icon {
    margin-bottom: 1rem;
    color: var(--primary-color);
    font-size: 4rem;
    transition: .2s;
    @include mix.mq-up(xl) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5rem;
    }
  }

  &__title {
    font-size: 1rem;
    line-height: 1.3;
    transition: .2s;
  }

  &__item:hover .ms2022-spring-board__icon,
  &__item:hover .ms2022-spring-board__title,
  &__item:hover .ms2022-spring-board__title--loan {
    color: white;
  }
}