[class^="ms2022-policy"] {
  .block1 {
    padding: 1rem;
  }
}

.ms2022-policy {
  ul, ol {
    margin-left: 0 !important;
    margin-right: 0;
  }

  #main > * {
    margin-bottom: 1rem !important;
  }

  #main > h1 {
    margin-bottom: 3rem !important;
  }

  #main > h2 {
    margin-bottom: 2rem !important;
  }

  #main > * + h3 {
    margin-top: 2rem !important;
  }
  
  #main > p + p {
    margin-top: 0 !important;
  }
}

.ms2022-policy-community {
  .borderBlock {
    text-align: left !important;
  }
}