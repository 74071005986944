$primary: #009fb8;
$secondary: #EBF6F8;

$colors: (
  primary: #009fb8,
  secondary: #EBF6F8
);

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
)