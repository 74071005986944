@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-container {
  padding-left: 1rem;
  padding-right: 1rem;
  @include mix.mq-up(xl) {
    max-width: calc(map-get(var.$breakpoints, xl) + 2rem);
    margin-left: auto;
    margin-right: auto;
  }
}