@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-campaign {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: rgba(var.$primary, .8);

  img {
    width: 100%;
    height: auto;
  }

  &__headline {
    color: white;
    text-align: center;
  }

  &__swiper-container {
    @include mix.mq-up(xl) {
      position: relative;
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }

  .swiper-pagination {
    position: static;
    margin-top: 1rem;
  }
  
  .swiper-pagination span {
    background: white;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
      color: white;
    }
  }

  .swiper-button-prev {
    left: 0;
  }
  
  .swiper-button-next {
    right: 0;
  }
}