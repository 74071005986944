@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

%sticky-bar {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 3rem;
  background: var.$primary;
  font-size: 1rem;
  color: white;
  text-align: center;
  cursor: pointer;
  transition: .2s;
  @include mix.mq-up(xl) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(3rem + 4px);
    height: auto;
    padding: 1rem 4px 1rem 0;
    transform: translateX(4px);
  }

  &:hover {
    @include mix.mq-up(xl) {
      transform: translateX(0);
    }
  }

  > span {
    font-size: .7em;
    opacity: .7;
  }
}

.ms2022-sticky {
  position: fixed;
  z-index: 40;
  left: 0;
  bottom: 0;
  width: 100%;
  @include mix.mq-up(xl) {
    display: flex;
    bottom: 50%;
    left: auto;
    right: 0;
    width: calc(3rem + 4px);
    transform: translateY(50%);
  }

  &__bars {
    display: flex;
    transition: .5s;
    @include mix.mq-up(xl) {
      gap: 1rem;
      writing-mode: vertical-lr
    }
  }

  &__bars.is-hide {
    opacity: 0;
    visibility: hidden;
  }

  &__bar {
    @extend %sticky-bar;
  }

  &__bar--corp {
    @extend %sticky-bar;
    background: #1D2087;
  }

  &__items {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    @include mix.mq-up(xl) {
      position: static;
    }
  }

  &__item {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    transition: transform .5s;
    @include mix.mq-up(xl) {
      position: fixed;
      top: 50%;
      left: auto;
      right: 0;
      width: 20rem;
      transform: translate(100%, -50%);
    }
  }

  &__item.is-show {
    transform: translateY(-100%);
    @include mix.mq-up(xl) {
      transform: translate(0, -50%);
    }
  }

  .ms2022-service-login {
    .ms2022-service-login__headline {
      background-color: var.$primary;
    }
    .ms2022-service-login__body {
      background-color: var.$secondary;
    }
    .ms2022-button--outline {
      color: var.$primary;
      border-color: var.$primary;
    }
    .ms2022-button--outline:hover {
      color: white;
      background-color: var.$primary;
    }
    .ms2022-button--outline::after {
      border-color: var.$primary;
    }
    .ms2022-button--outline:hover::after {
      color: white;
    }
  }

  .ms2022-service-login--corp {
    .ms2022-service-login__body {
      background-color: #f2f3ff;
    }
  }

  .ms2022-service-login__body {
    height: 100%;
  }
}