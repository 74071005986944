@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

[class^="ms2022-corp-cservice-direct"] {
  .block01.nav {
    padding: 1rem;
    
    .linkList {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      padding-left: 0;
    }

    li {
      margin-bottom: 0;
    }

    span {
      margin-right: 0;
      padding-right: 0;
      background: none;
    }
  }
}

.ms2022-corp-cservice-direct-faq {
  .qa {
    padding: 0;
    background: none !important;
  }

  .anchorList a:hover {
    text-decoration: none;
  }

  .questionList {
    li {
      list-style: disc;
      padding-left: 0;
      background: none;
      font-size: 1rem;
    }
  }
}

.ms2022-corp-cservice-direct-logon {
  margin-top: 3rem !important;

  #main {
    > h2 {
      display: none;
    }

    > .btnColor {
      @include mix.mq-up(xl) {
        width: 50%;
        margin: auto;
      }
    }
  }
  
  .securityArea {
    > .hd02 {
      margin-bottom: 2rem;
    }
    
    .linkList {
      margin-left: 0;
    }
  }

  .box01container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;
    background-color: transparent !important;
    @include mix.mq-up(xl) {
      flex-direction: row;
    }
    
    .fs18 {
      text-align: left !important;
      @include mix.mq-up(xl) {
        width: 100%;
      }
    }

    .box01 {
      display: block;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 0;
      box-shadow: none;
      @include mix.mq-up(xl) {
        display: inline-block;
        width: auto;
        flex: 1;
      }
    }
  }

  .bgColorB {
    padding: 0;
    background-color: transparent !important;
  }
}