@use "~/src/scss/base/mixins" as mix;
@use "./variables" as local-var;

@mixin lineHeight($line-height) {
  &::before,
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
  }
  &::before {
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }
  &::after {
    margin-bottom: calc((1 - #{$line-height}) * 0.5em);
  }
}

.ms2022-lp-kinri {
  @include mix.mq-up(xl) {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
  }

  &__caption {
    margin-bottom: 1rem;
    @include mix.m-plus-1p("DB");
    line-height: 1.3;
    @include mix.mq-up(xl) {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0;
    }
  }

  &__value {
    display: flex;
    align-items: flex-end;
    @include mix.m-plus-1p("DB");
    font-size: 5rem;
    margin-top: -.3em;
    @include mix.mq-up(xl) {
      font-size: 10rem;
    }
  }

  &__value > span:first-child {
    height: 1em;
    font-size: .3em;
    line-height: 1;
  }

  &__value > span:nth-child(2) {
    height: 1.15em;
    font-size: 1em;
    line-height: 1.5;
  }
  
  &__value > span:last-child {
    height: 1em;
    margin-bottom: -.1em;
    font-size: .5em;
    line-height: 1;
  }
}

.ms2022-lp-list {
  padding-left: 1.2em;

  &--disc > li {
    list-style-type: disc;
  }

  &--square > li {
    list-style-type: square;
  }
  
  &--asterisk {
    padding-left: 1em;
  }

  &--asterisk > li {
    text-indent: -1em;
  }

  &--asterisk li::before {
    content: "※";
  }

  &--num {
    padding-left: 1em;
  }

  &--num > li {
    text-indent: -1em;
  }
}

.ms2022-lp-heading {
  margin-bottom: 2rem;
  color: var(--lp-theme-color-primary);
  font-size: 1.5rem;
  @include mix.m-plus-1p("M");
  text-align: center;
  line-height: 1.3;
  letter-spacing: .1em;
  @include mix.mq-up(xl) {
    font-size: 3rem;
  }
}

.ms2022-lp-ihead {
  display: flex;
  align-items: center;
  gap: .5rem;
  color: var(--lp-theme-color-tertiary);
  @include mix.m-plus-1p("M");
  font-size: 1.5rem;
  line-height: 1.3;
  @include mix.mq-up(xl) {
    font-size: 2rem;
  }

  > img {
    width: 1em;
    margin-right: .5rem;
  }
}

.ms2022-lp-accordion {
  &__bar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 3rem;
    padding: .5rem 2.5rem;
    background-color: var(--lp-theme-color-primary);
    color: white;
    font-size: 1.25rem;
    text-align: center;
    cursor: pointer;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__bar > svg {
    content: "";
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 1rem;
    fill: white;
    transform: translateY(-50%) rotate(180deg);
    @include mix.mq-up(xl) {
      width: 1.5rem;
    }
  }

  &__bar.is-open > svg {
    transform: translateY(-50%) rotate(0deg);
  }

  &__body {
    border: 1px solid var(--lp-theme-color-primary);
    border-top: none;
    background-color: white;
  }
  
  &__inner {
    padding: 1rem;
  }

  &--outline &__bar {
    background-color: white;
    border: 1px solid var(--lp-theme-color-primary);
    color: var(--lp-theme-color-primary);
  }

  &--outline &__bar > svg {
    color: var(--lp-theme-color-primary);
  }
}

.ms2022-lp-header {
  margin: 0 -1rem;
  @include mix.m-plus-1p("M");
  text-align: center;
  @include mix.mq-up(sm) {
    display: flex;
    margin: 0;
    text-align: left;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    background-color: var(--lp-theme-color-primary);
    color: white;
    @include mix.mq-up(sm) {
      padding: 3rem 2rem 2rem;
    }
  }

  &__icon {
    width: 2rem;
    margin: 0 auto 1rem;
    @include mix.mq-up(sm) {
      margin-left: 25%;
    }
    @include mix.mq-up(xl) {
      width: 4rem;
    }
  }

  &__heading {
    margin-bottom: 1rem;
    line-height: 1.2;
    font-size: min(8vw, 3rem);
    @include mix.mq-up(xl) {
      margin-left: -1%;
      font-size: 4.5rem;
    }
  }

  &__heading > br:nth-of-type(2) {
    display: none;
    @media (orientation: landscape) {
      display: block;
    }
    @include mix.mq-up(sm) {
      display: block;
    }
  }

  &__heading > span {
    font-size: .8em;
  }

  &__text1 {
    margin-bottom: .5rem;
    line-height: 1.3;
    font-size: min(6vw, 1.25rem);
    @include mix.mq-up(xl) {
      font-size: 2rem;
    }
  }

  &__text1 > b {
    @include mix.m-plus-1p("B");
  }

  &__text1 > span {
    font-size: .95em;
  }

  &__text2 {
    line-height: 1.3;
    font-size: min(5vw, 1.125rem);
    @include mix.mq-up(xl) {
      font-size: 1.875rem;
    }
  }

  &__image {
    position: relative;
    flex: 1;
    overflow: hidden;
  }

  &__image::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-style: solid;
    border-width: 0 5rem 5rem 0;
    border-color: transparent #FFF100 transparent transparent;
    @include mix.mq-up(xl) {
      border-width: 0 10rem 10rem 0;
    }
  }

  &__image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ms2022-lp-web {
  padding: 1.5rem;
  border: 1px solid var(--lp-theme-color-accent);
  @include mix.mq-up(xl) {
    padding: 2rem 3rem;
  }

  &__heading {
    margin-bottom: 2rem;
    padding: .5rem;
    background-color: var(--lp-theme-color-accent);
    color: white;
    @include mix.m-plus-1p("B");
    font-size: 1.75rem;
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 3rem;
    }
  }

  &__box {
    @include mix.mq-up(xl) {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 2rem;
    }
  }

  &__text {
    margin-bottom: 1rem;
    @include mix.m-plus-1p("M");
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      margin-bottom: 0;
      font-size: 2rem;
      text-align: left;
    }
  }

  &__text > br:nth-child(1) {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  .ms2022-lp-kinri__caption {
    font-size: 1.25rem;
    text-align: center;
    @include mix.mq-up(xl) {
      text-align: left;
      font-size: 1.5rem;
    }
  }

  .ms2022-lp-kinri__caption > br:nth-child(2) {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  .ms2022-lp-kinri__value {
    justify-content: center;
    color: var(--lp-theme-color-accent);
  }
}

.ms2022-lp-feature {
  &__boxies {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 1rem;
    @include mix.mq-up(sm) {
      flex-direction: row;
      gap: 1rem;
    }
    @include mix.mq-up(md) {
      gap: 3rem;
    }
  }

  &__box {
    @include mix.mq-up(sm) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  &__image {
    padding: 2rem;
    text-align: center;
    background-color: var(--lp-theme-color-secondary);
  }

  &__image > img {
    height: 6rem;
  }

  &__heading {
    padding: 1rem;
    background-color: var(--lp-theme-color-primary);
    color: white;
    @include mix.m-plus-1p("M");
    font-size: 1.25rem;
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(sm) {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__notices {
    font-size: .875rem;
    color: #E50038;
    @include mix.m-plus-1p("DB");
    text-decoration: underline;
    @include mix.mq-up(xl) {
      font-size: 1rem;
    }
  }
}

.ms2022-lp-step {
  padding: 1.5rem;
  border: 1.5rem solid var(--lp-theme-color-secondary);
  @include mix.mq-up(xl) {
    padding: 2rem;
    border-width: 2rem;
  }

  &__heading {
    margin-bottom: 2rem;
    @include mix.m-plus-1p("M");
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.3;
  }

  &__heading > span {
    @include mix.m-plus-1p("DB");
    font-size: 1.5em;
    color: var(--lp-theme-color-primary);
  }

  &__flow {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: 2rem;
    @include mix.mq-up(sm) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
    @include mix.mq-up(xl) {
      justify-content: center;
    }
  }

  &__arrow {
    width: 3rem;
    fill: var(--lp-theme-color-secondary);
    transform: rotate(90deg);
    @include mix.mq-up(sm) {
      margin-top: 75px;
      transform: translateY(-50%);
    }
    @include mix.mq-up(xl) {
      align-self: flex-start;
    }
  }

  &__circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    margin-bottom: 1rem;
    background-color: var(--lp-theme-color-primary);
    border-radius: 999px;
  }

  &__subhead {
    color: white;
    @include mix.m-plus-1p("DB");
    font-size: 1.5rem;
  }

  &__icon {
    height: 70px;
  }

  &__text {
    @include mix.m-plus-1p("M");
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 1.25rem;
    }
  }
}

.ms2022-lp-visit {
  &__heading {
    margin-bottom: 2rem;
    padding: .5rem 0;
    background-color: var(--lp-theme-color-primary);
    color: white;
    font-size: 1.75rem;
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      margin-bottom: 4rem;
      font-size: 3rem;
    }
  }

  &__block:first-child {
    margin-bottom: 2rem;
    @include mix.mq-up(xl) {
      margin-bottom: 5rem;
    }
  }

  .ms2022-lp-kinri__caption {
    text-align: center;
    @include mix.mq-up(xl) {
      text-align: left;
    }
  }

  .ms2022-lp-kinri__caption > br:nth-child(2) {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  .ms2022-lp-kinri__value {
    justify-content: center;
  }
}

.ms2022-lp-discount {
  // padding: 1.5rem;
  // background-color: white;
  // @include mix.mq-up(xl) {
  //   padding: 2rem 2rem 3rem;
  // }

  &__header {
    margin-bottom: 2rem;
    color: var(--lp-theme-color-primary);
    @include mix.mq-up(xl) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }

  &__heading {
    display: flex;
    justify-content: center;
    gap: 2px;
    margin-bottom: 1rem;
    color: white;
    @include mix.m-plus-1p("DB");
    line-height: 1;
    font-size: 1.25rem;
    @include mix.mq-up(xl) {
      justify-content: flex-start;
      margin-bottom: 0;
      font-size: 2rem;
    }
  }

  &__heading > span {
    padding: .5rem 3px;
    background-color: var(--lp-theme-color-primary);
  }

  &__period {
    @include mix.m-plus-1p("M");
    font-size: 1.5rem;
    line-height: 1.2;
    text-align: center;
    @include mix.mq-up(xl) {
      display: flex;
      font-size: 2.75rem;
      text-align: left;
      line-height: 1;
    }
  }

  &__period > span {
    display: block;
    font-size: .8em;
    line-height: 1.2;
    @include mix.mq-up(xl) {
      margin-right: 1rem;
      padding-right: 1rem;
      border-right: 1px solid;
      font-size: 1.25rem;
    }
  }

  &__period > span > br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__amounts {
    display: flex;
    flex-direction: column;
    @include mix.mq-up(xl) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      gap: 2rem;
    }
  }
  
  &__min {
    margin-bottom: 2rem;
    color: var(--lp-theme-color-accent);
    font-size: 1rem;
    @include mix.mq-up(xl) {
      margin-bottom: 0;
      font-size: 1.5rem;
    }
  }
  
  &__min .ms2022-lp-kinri__value {
    @include mix.mq-up(xl) {
      font-size: 10rem;
    }
  }

  &__max {
    @include mix.mq-up(xl) {
      margin-bottom: -1.75rem;
    }
  }
}

.ms2022-lp-max-rate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  color: var(--lp-theme-color-primary);
  @include mix.mq-up(xl) {
    align-items: flex-end;
    gap: 1rem;
  }

  &__title {
    padding: 0 1rem;
    background-color: var(--lp-theme-color-primary);
    color: white;
    @include mix.m-plus-1p("DB");
    @include mix.mq-up(xl) {
      width: calc(100% - 6rem);
      padding: .5rem 1rem;
      text-align: center;
    }
  }

  &__amount {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    @include mix.mq-up(xl) {
      gap: 2rem;
    }
  }

  &__leading {
    margin-bottom: 1rem;
    font-size: 2rem;
    @include mix.mq-up(xl) {
      margin-bottom: 0;
      font-size: 4rem;
    }
  }

  &__notices {
    margin-top: .5rem;
    color: var(--lp-theme-color-primary);
    font-feature-settings: "palt";
    text-align: right;
  }

  .ms2022-lp-kinri__value {
    font-size: 3rem;
    @include mix.mq-up(xl) {
      font-size: 4.5rem;
    }
  }
}

.ms2022-lp-repeat {
  @include mix.mq-up(xl) {
    display: flex;
    gap: 1rem;
  }
  
  &__header {
    display: flex;
    // flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
    @include mix.mq-up(xl) {
      gap: 3rem;
      margin-bottom: 0;
    }
  }

  &__overview {
    flex: 1;
    @include mix.mq-up(xl) {
      flex: none;
      display: flex;
      gap: 1rem;
    } 
  }

  &__conditions {
    flex: 1;
    align-self: flex-start;
    @include mix.mq-up(xl) {
      flex: none;
    }
  }

  &__tag {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto .5rem;
    padding: .25rem;
    border-radius: .75rem;
    background-color: var(--lp-theme-color-accent);
    color: white;
    text-align: center;
    font-size: 1.25rem;
    line-height: 1.2;
    @include mix.mq-up(xl) {
      width: 10rem;
      padding: .25rem 1rem;
    }
  }
  
  &__tag > br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__overview &__tag {
    @include mix.mq-up(xl) {
      margin-bottom: 0;
    }
  }

  &__remarks {
    margin-bottom: 1rem;
    @include mix.m-plus-1p("M");
    font-size: 1.125rem;
    text-align: center;
    @include mix.mq-up(xl) {
      margin-bottom: 0;
    }
  }

  .ms2022-lp-list {
    @include mix.mq-up(xl) {
      flex: 1;
    }
  }

  .ms2022-lp-kinri {
    @include mix.mq-up(xl) {
      gap: 0;
    }
  }

  .ms2022-lp-kinri__caption {
    font-size: 1.5rem;
    @include mix.mq-up(xl) {
      font-size: 2rem;
      line-height: 1.2;
    }
  }

  .ms2022-lp-kinri__caption span {
    font-size: 1.125rem;
  }

  .ms2022-lp-kinri__value {
    color: var(--lp-theme-color-accent);
    font-size: 3.5rem;
    @include mix.mq-up(xl) {
      font-size: 5.5rem;
    }
  }
}

.ms2022-lp-discount-detail {
  &__condition {
    padding: 0 .5rem;
    background-color: var(--lp-theme-color-primary);
    color: white;
    font-size: 1.25rem;
    text-align: center;
    @include mix.mq-up(xl) {
      width: 3rem;
      height: auto;
      padding: 0;
      font-size: 1.5rem;
      letter-spacing: .2em;
      line-height: 3rem;
      writing-mode: vertical-lr;
    }
  }

  &__block:first-child {
    @include mix.mq-up(xl) {
      display: flex;
      border-bottom: 2px solid var(--lp-theme-color-primary);
      background-color: white;
    }
  }

  &__block:first-child .ms2022-lp-list {
    @include mix.mq-up(xl) {
      margin-left: 2rem;
    }
  }

  &__block:last-child {
    @include mix.mq-up(xl) {
      padding: 3rem;
    }
  }

  &__box {
    color: var(--lp-theme-color-tertiary);
  }

  &__box + .ms2022-lp-discount-detail__box {
    margin-top: 3rem;
  }

  &__heading,
  &__subhead {
    display: table;
    margin: 0 auto 1rem;
    padding: .5rem 1rem;
    border-radius: .75rem;
    background-color: var(--lp-theme-color-tertiary);
    color: white;
    @include mix.m-plus-1p("M");
    text-align: center;
    line-height: 1.2;
    letter-spacing: .2em;
    @include mix.mq-up(xl) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  &__heading {
    font-size: 1.25rem;
    @include mix.mq-up(xl) {
      width: 18rem;
      font-size: 2rem;
    }
  }

  &__subhead {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    @include mix.mq-up(xl) {
      width: 12rem;
    }
  }

  .ms2022-lp-accordion {
    position: relative;
    margin-bottom: 5rem;
    @include mix.mq-up(xl) {
      display: flex;
      margin: -2rem -2rem 3rem;
    }
  }

  .ms2022-lp-accordion::after {
    content: "";
    position: absolute;
    top: calc(100% + 1rem);
    left: 50%;
    border: 2rem solid transparent;
    border-top-color: var(--lp-theme-color-primary);
    transform: scaleY(.7) translateX(-50%);
    transform-origin: top;
    @include mix.mq-up(xl) {
      top: 100%;
      border-width: 4rem
    }
  }

  .ms2022-lp-accordion__bar {
    background-color: white;
    border: 2px solid var(--lp-theme-color-primary);
    color: var(--lp-theme-color-primary);
  }

  .ms2022-lp-accordion__bar > svg {
    fill: var(--lp-theme-color-primary);
  }

  .ms2022-lp-accordion__bar::after {
    @include mix.mq-up(xl) {
      display: none;
    }
  }
  
  .ms2022-lp-accordion__body {
    border: 2px solid var(--lp-theme-color-primary);
    border-top: none;
  }

  .ms2022-lp-accordion__inner {
    padding-top: 2rem;
    padding-bottom: 2rem;
    @include mix.mq-up(xl) {
      padding: 0;
    }
  }
  
  .ms2022-lp-accordion ol {
    margin: 0;
    @include mix.mq-up(xl) {
      font-size: 1.125rem;
    }
  }
}

.ms2022-lp-special-discount {
  @include mix.mq-up(xl) {
    display: flex;
    justify-content: space-between;
    gap: 6rem;
  }

  &__rate {
    margin-bottom: 2rem;
    @include mix.mq-up(xl) {
      flex: 1;
      margin-bottom: 0;
    }
  }

  &__rate .ms2022-lp-kinri__value {
    @include mix.mq-up(xl) {
      font-size: 9rem;
    }
  }

  &__terms {
    @include mix.mq-up(xl) {
      flex: .8;
    }
  }
  
  &__terms p {
    margin-bottom: 1rem;
    @include mix.m-plus-1p("M");
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
      text-align: left;
    }
  }

  .ms2022-lp-kinri {
    @include mix.mq-up(xl) {
      justify-content: flex-end;
      margin-top: 3rem;
    }
  }

  .ms2022-lp-kinri__value {
    justify-content: center;
    @include mix.mq-up(xl) {
      justify-content: flex-start;
    }
  }

  .ms2022-lp-list {
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }
}

.ms2022-lp-item-discount {
  .ms2022-lp-discount-detail__heading {
    color: var(--lp-theme-color-tertiary);
    border: 1px solid var(--lp-theme-color-tertiary);
    background-color: white;
  }

  &__topic {
    @include mix.mq-up(xl) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__topic:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__topic > p {
    margin-bottom: .5rem;
    @include mix.m-plus-1p("M");
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
    letter-spacing: .1em;
    @include mix.mq-up(xl) {
      margin-bottom: 0rem;
      font-size: 2rem;
      text-align: left;
    }
  }
  
  &__topic .ms2022-lp-kinri__value {
    justify-content: center;
    font-size: 4rem;
    @include mix.mq-up(xl) {
      justify-content: flex-start;
      font-size: 6rem;
    }
  }
}

.ms2022-lp-sticky {
  position: fixed;
  z-index: 40;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background-color: rgba(white, .8);

  &__text {
    margin-bottom: .5rem;
    color: var(--lp-theme-color-accent);
    font-size: .75rem;
    text-align: center;
    @include mix.mq-up(xl) {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
  }

  // &__buttons {
  //   display: flex;
  //   gap: 1rem;
  //   max-width: 30rem;
  //   min-height: 3rem;
  //   margin: auto;
  //   @include mix.mq-up(xl) {
  //     gap: 2rem;
  //     max-width: 62rem;
  //     min-height: 6rem;
  //   }
  // }

  &__button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    margin: auto;
    padding: .25rem 1rem;
    border: none;
    border-radius: .5rem;
    background-color: var(--lp-theme-color-accent);
    color: white !important;
    @include mix.m-plus-1p("DB");
    font-size: .875rem;
    text-align: center;
    text-decoration: none;
    line-height: 1.3;
    cursor: pointer;
    transition: background .2s;
    @include mix.mq-up(xl) {
      width: 30rem;
      height: 5rem;
      font-size: 2rem;
    }
  }

  &__button:hover {
    background-color: var(--lp-theme-color-accent-light);
  }

  &__button.is-disabled {
    background-color: var(--lp-theme-color-primary);
  }
}

.ms2022-lp-permission {
  padding: 2rem;
  background-color: var(--lp-theme-color-secondary);
  @include mix.mq-up(xl) {
    max-width: 1000px;
    margin: auto;
  }

  &__summary {
    margin-bottom: 2rem;
  }

  &__caption {
    margin-bottom: 1rem;
  }

  &__block {
    margin-bottom: 3rem;
  }

  &__heading {
    margin-bottom: 1rem;
    font-size: 1.125rem;
    @include mix.m-plus-1p("M");
  }

  &__content {
    overflow-y: scroll;
    height: 25rem;
    margin-bottom: 1rem;
    padding: 2rem;
    background-color: white;
  }

  &__content--small {
    overflow-y: visible;
    height: auto;
  }

  &__content > h3,
  &__content > h4 {
    margin-top: 2rem;
    font-size: 1rem;
    @include mix.m-plus-1p("M");
  }

  &__content > h3,
  &__content > h4,
  &__content > p,
  &__content > ul {
    margin-bottom: 1rem;
  }
  
  &__content li {
    margin-bottom: .5rem;
  }

  &__content > *:first-child {
    margin-top: 0;
  }

  &__content > *:last-child {
    margin-bottom: 0;
  }

  label {
    cursor: pointer;
  }

  input {
    margin-right: .5rem;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5rem;
    margin: auto;
    padding: .25rem 1rem;
    border-radius: .5rem;
    background-color: var(--lp-theme-color-accent);
    color: white !important;
    @include mix.m-plus-1p("DB");
    font-size: 1.25rem;
    text-align: center;
    text-decoration: none;
    line-height: 1.3;
    transition: background .2s;
    @include mix.mq-up(xl) {
      font-size: 2rem;
    }
  }

  &__button:hover {
    background-color: var(--lp-theme-color-accent-light);
  }

  &__button.is-disabled {
    pointer-events: none;
    background-color: #ccc;
  }
}

.ms2022-lp-points {
  &__heading {
    margin-bottom: 3rem;
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("M");
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 3rem;
    }
  }

  &__heading > span {
    display: inline-block;
    vertical-align: bottom;
    width: 1.4em;
    height: 1.4em;
    margin: 0 4px;
    background-color: var(--lp-theme-color-tertiary);
    border-radius: 999px;
    color: white;
    @include mix.m-plus-1p("B");
    font-size: 1.4em;
    line-height: 1.4em;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include mix.mq-up(xl) {
      flex-direction: row;
      justify-content: space-between;
      gap: 0;
    }
  }

  &__group > div {
    @include mix.mq-up(xl) {
      flex: 1;
    }
  }

  &__subject {
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("DB");
    font-size: 1.25rem;
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 1.75rem;
    }
  }
  
  &__text {
    margin-top: .5rem;
    text-align: center;
  }

  &__text > br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  .ms2022-lp-circle {
    width: 200px;
    margin: 0 auto 1rem;
  }
}

.ms2022-lp-circle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto 1rem;
  background-color: var(--lp-theme-color-tertiary);
  border-radius: 999px;
  
  &__inner {
    position: relative;
    width: 100%;
    padding-top: 100%;
  }
  
  &__inner > div {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  &__subhead {
    margin-bottom: 1rem;
    color: white;
    @include mix.m-plus-1p("DB");
    font-size: 1.5rem;
    line-height: 1;
  }

  &__subhead > span {
    font-size: 1.5em;
  }
  
  &__icon {
    flex: .7;
    @-moz-document url-prefix() {
      height: 90px;
    }
  }

  &__icon > img {
    height: 100%;
  }
}