.ms2022-corp-cashless {
  #main {
    > a {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    > a > img {
      margin-bottom: 1rem;
    }

    a[href="https://merc.li/eTKdRba"] > img {
      width: 8rem;
    }

    a[href="https://www.shinkincard.co.jp/"] > img {
      width: 8rem;
    }
  
    li:first-of-type {
      display: none;
    }
  }
}