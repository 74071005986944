@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

%news-tab-link {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  padding: 0 .5rem;
  border: 1px solid;
  border-bottom-color: transparent;
  text-align: center;
  line-height: 1.2;
  cursor: pointer;
  transition: .2s;
  @include mix.mq-up(xl) {
    font-size: 1.25rem;
  }
}

.ms2022-news-tab {
  &__header {
    border-bottom: 1px solid;
  }

  &__links {
    display: flex;
    align-items: flex-end;
    gap: .5rem;
    height: 3rem;
    margin-bottom: -1px;
    @include mix.mq-up(xl) {
      height: 4rem;
    }
  }

  &__link--latest {
    @extend %news-tab-link;
    color: var.$primary;
  }

  &__link--info {
    @extend %news-tab-link;
    color: black;
    border-top-color: #ccc;
    border-left-color: #ccc;
    border-right-color: #ccc;
  }
  
  &__link--important {
    @extend %news-tab-link;
    color: red;
  }

  &__content {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  &__more {
    display: block;
    margin-top: 1rem;
    text-align: right;
  }

  &__more > a {
    color: inherit;
  }

  &[data-tab-active-index="0"] {
    .ms2022-news-tab__header {
      border-bottom: 1px solid var.$primary;
    }
    .ms2022-news-tab__link--latest {
      height: 100%;
      color: var.$primary;
      border-top-color: var.$primary;
      border-left-color: var.$primary;
      border-right-color: var.$primary;
      border-bottom-color: white;
    }
    .ms2022-news-tab__content:first-child {
      display: block;
      opacity: 1;
      visibility: visible;
      animation: fadeIn .5s forwards;
    }
    .ms2022-news-tab__more {
      color: var.$primary;
    }
  }

  &[data-tab-active-index="1"] {
    .ms2022-news-tab__header {
      border-bottom-color: #ccc;
    }
    .ms2022-news-tab__link--info {
      height: 100%;
      border-top-color: #ccc;
      border-left-color: #ccc;
      border-right-color: #ccc;
      border-bottom-color: white;
    }
    .ms2022-news-list__title {
      color: black;
    }
    .ms2022-news-tab__content:nth-child(2) {
      display: block;
      opacity: 1;
      visibility: visible;
      animation: fadeIn .5s forwards;
    }
    .ms2022-news-tab__more {
      color: black;
    }
  }

  &[data-tab-active-index="2"] {
    .ms2022-news-tab__header {
      border-bottom-color: red;
    }
    .ms2022-news-tab__link--important {
      height: 100%;
      color: red;
      border-top-color: red;
      border-left-color: red;
      border-right-color: red;
      border-bottom-color: white;
    }
    .ms2022-news-list__title {
      color: red;
    }
    .ms2022-news-tab__content:last-child {
      display: block;
      opacity: 1;
      visibility: visible;
      animation: fadeIn .5s forwards;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}