@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-about {
  margin-top: -1rem !important;
  @include mix.mq-up(xl) {
    margin-top: -3rem !important;
  }

  &__keyvisual img {
    width: 100%;
  }

  .cautionArea,
  #main > h2:first-child,
  #main > ul {
    display: none;
  }

  #main > *:not(:last-child) {
    margin-bottom: 3rem;
  }

  #main > .h01 {
    margin-top: 3rem;
  }

  &__subhead {
    margin-bottom: 2rem !important;
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  .ms2022-spring-board {
    @include mix.mq-up(xl) {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}

.ms2022-about-company .h01 ~ .hd02,
.ms2022-about-disclosure .h01 ~ .hd02,
.ms2022-about-area .h01 ~ .hd02,
.ms2022-about-circle .h01 ~ .hd02,
.ms2022-about-kanren .h01 ~ .hd02 {
  position: relative;

  &::before {
    z-index: -2;
  }

  &::after {
    @include mix.mq-up(xl) {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
      width: 70%;
      padding-top: 13.7%;
      background: url(/dist/ms2022-assets/images/about/illustration.png) no-repeat center / cover;
    }
  }
}