@use "~/src/scss/base/mixins" as mix;

.ms2022-relational-buttons {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 0 !important;
  @include mix.mq-up(xl) {
    flex-direction: row;
  }

  &--center {
    justify-content: center;
  }

  > a {
    flex: 0.333;
  }

  > a:hover {
    text-decoration: none;
  }

  > a:empty {
    display: none;
  }
}