@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-home {
  margin-top: -1rem !important;
  @include mix.mq-up(xl) {
    margin-top: -3rem !important;
  }
  
  @include mix.mq-up(xl) {
    margin-top: 0;
  }

  &__sec {
    &:not(:last-child) {
      margin-bottom: 5rem;
    }
    
    &:first-child > *:not(:last-child) {
      margin-bottom: 3rem;
    }
  
    &:first-child > *:first-child {
      margin-bottom: 4rem;
    }
  }

  &__head {
    margin-bottom: 2rem;
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__main-slider {
    margin-top: -1rem;
  
    .swiper-slide {
      text-align: center;
      // @include mix.mq-up(xl) {
      //   padding: 0 4rem;
      // }
    }
  
    .swiper-slide img {
      width: 100%;
      transition: .3s;
    }
    
    .swiper-pagination {
      position: static;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      background-color: white;
    }
    
    .swiper-pagination span {
      background: var.$primary;
    }
  }

  &__pickup {
    @include mix.mq-up(xl) {
      display: flex;
      max-width: map-get(var.$breakpoints, xl);
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
    }
  }

  &__app {
    margin-bottom: 3rem;
    @include mix.mq-up(xl) {
      margin: 0 2rem 0 0;
    }
  }
  
  &__login {
    margin-bottom: 2rem;
    @include mix.mq-up(xl) {
      flex: 1;
      display: flex;
      margin-bottom: 0;
    }

    > [class^="ms2022-service-login"] {
      @include mix.mq-up(xl) {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
    }
  
    > [class^="ms2022-service-login"]:first-child {
      margin-bottom: 2rem;
      @include mix.mq-up(xl) {
        margin: 0 1rem 0 0;
      }
    }
  }
}