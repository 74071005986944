@use "~/src/scss/base/mixins" as mix;

.ms2022-corp {
  margin-top: -1rem !important;
  @include mix.mq-up(xl) {
    margin-top: -3rem !important;
  }

  &__slider {
    .swiper-pagination {
      position: static;
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      background-color: white;
    }

    img {
      width: 100%;
      transition: .3s;
    }
  }

  &__subhead {
    margin-bottom: 2rem !important;
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  .cautionArea,
  #main > h2:first-child,
  #main > ul {
    display: none;
  }

  #main > *:not(:last-child) {
    margin-bottom: 5rem !important;
  }

  #main > .h01 {
    margin-top: 3rem;
  }

  .ms2022-service-login--corp {
    margin-bottom: 2rem;
  }

  .ms2022-service-login__boxies {
    @include mix.mq-up(xl) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }
  }
}