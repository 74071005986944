#wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

#left {
  display: none;
}

#main {
  float: none;
  width: 100%;

  p {
    margin-left: initial;
  }
}

@media all and (-ms-high-contrast: none) {
  #wrapper {
    width: 990px;
    margin: 0 auto 50px auto;
  }

  #left {
    display: block;
  }

  #main {
    float: right;
    width: 740px;
  }
}