@use "~/src/scss/base/variables" as var;

.h01,
.h02,
.hd02 {
  padding: 0 0 0 1rem;
  border: none;
  border-left: 4px solid var(--primary-color);
  background: none;
  color: black;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.3;

  > * {
    font-size: inherit;
  }
}
  
.h02,
.hd02 {
  position: relative;
  padding: 0 0 .75rem 1rem;
  font-size: 1.25rem;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -4px;
    width: calc(100% + 4px);
    height: 8px;
    background-color: white;
    border-bottom: 1px solid #ccc;
  }
}

.h03 {
  padding: 0;
  border: none;
  background: none;
  font-weight: bold;
}

.h03noLine {
  padding: 0;
  background: none;
  font-weight: bold;
}

.h04 {
  padding: 0;
  background: none;
}

.hd02,
.h03 {
  display: flex;
  align-items: center;

  [class^="icon"] {
    margin-left: 1rem;
    font-size: 3rem;
    color: var(--primary-color);
  }
}