@import "/node_modules/swiper/swiper.scss";
@import "/node_modules/swiper/modules/pagination/pagination.scss";
@import "/node_modules/swiper/modules/navigation/navigation.scss";
$fa-font-path: "/node_modules/@fortawesome/fontawesome-free/webfonts";
@import "/node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "/node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "vendor/icomoon";

:root {
  --primary-color: #009fb8;
  --secondary-color: #EBF6F8;
}

.ms2022-legacy {
  display: none;
}

@media all and (-ms-high-contrast: none) {
  .ms2022-modern {
    display: none;
  }

  .ms2022-legacy {
    display: block;
  }
}

[class^="ms2022"] {
  @import "base/default";

  // コンポーネント
  @import "components/anchor-list";
  @import "components/app-qr";
  @import "components/back-to-top";
  @import "components/banking-app";
  @import "components/banners";
  @import "components/blank-button";
  @import "components/blank";
  @import "components/block";
  @import "components/branch";
  @import "components/breadcrumb";
  @import "components/button";
  @import "components/campaign";
  @import "components/card";
  @import "components/cash";
  @import "components/category";
  @import "components/caution";
  @import "components/container";
  @import "components/feature";
  @import "components/footer";
  @import "components/gnav";
  @import "components/header";
  @import "components/headline";
  @import "components/inquiry";
  @import "components/layout";
  @import "components/link-button";
  @import "components/link-list";
  @import "components/list";
  @import "components/news-tab";
  @import "components/news-list";
  @import "components/notebox";
  @import "components/pdf-button";
  @import "components/qa-block";
  @import "components/relational-buttons";
  @import "components/scroll-box";
  @import "components/service-login";
  @import "components/sitemap";
  @import "components/special-box";
  @import "components/spring-board";
  @import "components/sticky";
  @import "components/table";
  @import "components/template";
  @import "components/width";
  @import "components/youtube";
  
  // ページ
  @import "pages/home";
  @import "pages/personal/apply";
  @import "pages/personal/apply/investment";
  @import "pages/personal/loan";
  @import "pages/personal/loan/simulation";
  @import "pages/personal/loan/lp/index";
  @import "pages/personal/pservice";
  @import "pages/corp";
  @import "pages/corp/cashless";
  @import "pages/corp/direct";
  @import "pages/corp/gain";
  @import "pages/about";
  @import "pages/about/circle";
  @import "pages/about/company";
  @import "pages/about/disclosure";
  @import "pages/others/faq";
  @import "pages/policy";
  @import "pages/policy/insurance";
  @import "pages/kinri";
  @import "pages/notice";
  @import "pages/tesuryo";
  @import "pages/atm";
  @import "pages/sitemap";
}
