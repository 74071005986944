@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

%ms2022-category-menu {
  border: 1px solid gray;
  text-align: center;
  @include mix.mq-up(xl) {
    min-height: 5rem;
  }

  &:hover {
    border-color: white;
    background-color: white;
    color: var(--primary-color);
  }

  &:hover::after {
    border-color: var(--primary-color);
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
    @include mix.mq-up(xl) {
      margin-bottom: 0;
    }
  }
}

.ms2022-category {
  font-size: 1rem;
  background: var(--primary-color);

  &__bar {
    position: relative;
    z-index: 10;
    height: 100%;
    min-height: 3rem;
    border-bottom: 1px solid white;
    color: white;
    font-weight: bold;
    line-height: 1.3;
    box-sizing: border-box;
    transition: .2s;
    cursor: pointer;
    @include mix.mq-up(xl) {
      border-top: 1px solid var(--primary-color);
      border-bottom: 1px solid var(--primary-color);
      border-left: none;
      text-align: center;
      line-height: normal;
      cursor: initial;
    }
  }
  
  &__bar > * {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem 1rem;
    color: inherit;
    text-decoration: none;
    @include mix.mq-up(xl) {
      flex-direction: column;
      justify-content: center;
      padding: .5rem;
    }
  }

  &__bar > a {
    color: inherit;
    cursor :pointer;
  }

  &__bar > *::after {
    content: "\e903";
    position: absolute;
    top: 50%;
    right: 1rem;
    font-family: 'icomoon';
    font-size: .75rem;
    transform: translateY(-50%) rotate(90deg);
    @include mix.mq-up(xl) {
      position: static;
      transform: rotate(90deg);
    }
  }

  &__bar:hover,
  &__bar.is-show {
    background-color: white;
    color: var(--primary-color);
    transition-delay: 200ms;
  }

  &__bar:not(.is-show):hover {
    border-bottom-color: var(--primary-color);
  }

  &__bar.is-show > *::after {
    transform: translate(1px, -50%) rotate(-90deg);
    @include mix.mq-up(xl) {
      transform: rotate(90deg);
    }
  }

  &__container {
    overflow: hidden;
    height: 0;
    @include mix.mq-up(xl) {
      position: absolute;
      top: var(--gnav-bar-height);
      left: 0;
      z-index: -1;
      width: 100%;
      display: flex;
      align-items: flex-start;
      gap: 3rem;
      overflow: visible;
      height: auto;
      padding: 2rem calc((100vw - map-get(var.$breakpoints, xl) - 14px) / 2);
      background-color: var(--secondary-color);
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, -100%, 0);
      transition: .5s;
    }
  }

  &__bar.is-show + .ms2022-category__container {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  &__login {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
      width: 20rem;
    }
  }

  &__menus {
    padding: 1rem;
    background-color: var(--secondary-color);
    @include mix.mq-up(xl) {
      flex: 1;
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(2, 1fr);
      padding: 0;
      background: none;
    }
  }

  &__menu {
    @extend %ms2022-category-menu;
  }
  
  &__menu--cattop {
    @extend %ms2022-category-menu;
    @include mix.mq-up(xl) {
      display: none;
    }
  }
}