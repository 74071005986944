$accent: #E50038;

$carlife: (
  primary: #569DD6,
  secondary: #E2ECF7,
  tertiary: #005EAC,
);

$education: (
  primary: #00AF95,
  secondary: #D6ECE5,
  tertiary: #005EAC,
);

$castle: (
  primary: #F8CE00,
  secondary: #E2ECF7,
  tertiary: #005EAC,
);