.ms2022-app-qr {
  display: flex;
  gap: 2rem;

  &__box {
    width: 9rem;
  }
  
  img:last-child {
    display: block;
    width: 100%;
    height: auto;
  }
}