@use "~/src/scss/base/variables" as var;

.block1 {
  background-color: var.$secondary !important;

  > .pr10 {
    padding-right: 0 !important;
  }

  *:last-child {
    margin-bottom: 0 !important;
  }
}

.block1,
.block1indent,
.block2 div,
.block2indent div,
.block3 div,
.block3indent div {
  width: 100%;
  margin-left: 0;
  padding: 1rem;
}

.block1indent {
  .ml25 {
    margin-left: 0 !important;
  }
}

.borderBlock {
  padding: 1rem !important;
}