@use "~/src/scss/base/mixins" as mix;

.ms2022-about-company-message {
  .leftImage {
    max-width: 40rem;
    margin: auto;
    padding: 250px 0 0 0;
    line-height: 2;
    background-position: top center;
    @include mix.mq-up(xl) {
      max-width: none;
      padding: 0 0 0 240px;
      background-position: left;
    }
  }
}

.ms2022-about-company-symbol {
  .symbolImage {
    background-position: top center;
    padding-top: 10rem;
    padding-left: 0;

    [class^="txtColor"] {
      font-size: 1.25rem;
    }
  }
}