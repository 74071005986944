@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-service-login {
  &__headline {
    height: 2.5rem;
    background: var(--primary-color);
    color: white;
    text-align: center;
    line-height: 2.5rem;
    @include mix.mq-up(xl) {
      font-size: 1.25rem;
    }
  }

  &__body {
    width: 100%;
    padding: 1rem;;
    background: var(--secondary-color);
    @include mix.mq-up(xl) {
      flex: 1;
    }
  }

  &__box:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__subhead {
    margin-bottom: .5rem;
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 1.125rem;
    }
  }

  &--corp {
    .ms2022-service-login__headline {
      background: #1D2087;
    }
    .ms2022-service-login__body {
      background: #f2f3ff;
    }
    .ms2022-service-login__button {
      color: #1D2087;
      border-color: #1D2087;
    }
    .ms2022-service-login__button:hover {
      background-color: #1D2087;
      color: white;
    }
    .ms2022-service-login__button::after {
      border-color: #1D2087;
    }
    .ms2022-service-login__button:hover::after {
      border-color: white;
    }
  }

  &--outline {
    padding: 1rem 0;
    border: 2px solid var(--primary-color);
    background: var(--secondary-color);
    .ms2022-service-login__headline {
      background: transparent;
      color: black;
    }
  }
}