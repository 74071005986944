.qaBlock {
  dt {
    padding-left: 0;
    background: none;
  }

  dd {
    padding-left: 0;
    background: none;
  }
}
