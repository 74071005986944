@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

header {
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  height: auto;
  margin-bottom: 1rem;
  padding: 1rem .5rem 1rem;
  border-bottom: 4px solid var(--primary-color);
  background-color: white;
  @include mix.mq-up(xl) {
    z-index: 20;
    margin-bottom: 0;
    padding: 1rem 0 .5rem;
  }

  .inner {
    position: static;
    display: flex;
    align-items: center;
    gap: .5rem;
    width: 100%;
    @include mix.mq-up(xl) {
      width: map-get(var.$breakpoints, xl);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .inner::after {
    display: none;
  }

  .inner > h1 {
    position: static;
    display: block;
    width: 10rem;
    margin: 0 auto 0 8px;
    font-size: 0;
    @include mix.mq-up(xl) {
      width: 15rem;
      margin: 0 auto 0 0;
    }
  }

  .inner > h1 img {
    width: 100%;
    height: auto;
  }

  .inner > p,
  .topBlock > dl,
  .btmBlock {
    display: none;
  }

  .inner > .rightArea {
    position: static;
    width: auto;
    padding: 0;
    @include mix.mq-up(xl) {
      order: 99;
    }
  }

  .inner > .rightArea > .topBlock {
    padding-bottom: 0;
  }

  .inner > .rightArea [onclick="window.close();"] {
    display: none;
  }

  .searchArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    padding: 1rem .5rem 1rem 1rem!important;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s;
    @include mix.mq-up(xl) {
      position: static;
      overflow: hidden;
      width: 0;
      padding: 0 0 0 1rem !important;
      opacity: 1;
      visibility: visible;
      box-shadow: none;
      transition: width .2s;
    }
  }
  
  .searchArea.is-show {
    opacity: 1;
    visibility: visible;
  }

  .searchArea form {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .searchArea form[action="/cgi-bin/_search/search.cgi"] {
    display: none;
  }
  
  .searchArea input[type=text] {
    flex: 1;
    width: auto !important;
    height: 2.5rem;
    padding: .5rem;
    border: 1px solid var(--primary-color);
    font-size: 1rem;
  }

  .searchArea input[type=text]:focus {
    outline: none;
  }

  .searchArea input[type=submit] {
    display: none;
  }

  .searchArea button {
    min-width: 3rem;
    margin: 0;
    border: none;
    background: none;
    color: var(--primary-color);
    outline: none;
    appearance: initial;
  }
  
  .searchArea button > i {
    font-size: 1.5rem;
  }

  .inner > .ms2022-icon-button {
    @include mix.mq-up(xl) {
      display: none;
    }
  }
}

.ms2022-nav-buttons {
  display: flex;
  align-items: center;
  @include mix.mq-up(xl) {
    gap: 1rem;
  }

  &__item:nth-child(1),
  &__item:nth-child(2) {
    display: none;
    @include mix.mq-up(xl) {
      display: flex;
    }
  }

  &__item:nth-child(3) {
    display: none;
    @include mix.mq-up(xl) {
      display: flex;
    }
  }
}

.ms2022-icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
  min-height: 3rem;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  cursor: pointer;

  &__icon {
    margin-bottom: .25rem;
    color: var(--primary-color);
    font-size: 1.5rem;
    @include mix.mq-up(xl) {
      margin-bottom: .5rem;
      font-size: 2rem;
    }
  }

  &__text {
    color: black;
    font-size: 12px;
  }

  &__bars {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: .25rem;
    @include mix.mq-up(xl) {
      margin-bottom: .5rem;
    }
  }
  
  &__bars > * {
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    width: 100%;
    height: 2px;
    background-color: var(--primary-color);
    transition: .3s;
  }

  &__bars > *:first-child {
    transform: translateY(-8px);
  }

  &__bars > *:last-child {
    transform: translateY(8px);
  }

  &__text {
    color: black;
    font-size: 12px;
  }

  &.is-show .ms2022-icon-button__bars > *:first-child {
    transform: rotate(45deg);
  }

  &.is-show .ms2022-icon-button__bars > *:nth-child(2) {
    opacity: 0;
    transform: translateX(-10%);
  }

  &.is-show .ms2022-icon-button__bars > *:last-child {
    transform: rotate(-45deg);
  }
}
