@use "~/src/scss/base/mixins" as mix;

.ms2022-personal-apply-flow {
  #main > .flowBlock2indent:not(.mb30) {
    margin-bottom: 0 !important;
  }

  .flowBlock2indent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding-left: 0;
    @include mix.mq-up(xl) {
      flex-direction: row;
      gap: 3rem;
    }

    .flL {
      @include mix.mq-up(xl) {
        margin-right: 0;
      }
    }

    .flR {
      width: auto;
    }
  }
}

.ms2022-personal-apply-toushin_is {
  form .blankBtn {
    @include mix.button($blank: true);
    cursor: pointer;
  }
}