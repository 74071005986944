@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

* {
  box-sizing: border-box;
}

a {
  color: var(--primary-color);
}

a:visited {
  color: var(--primary-color);
}

img {
  max-width: 100%;
  vertical-align: middle;
  @include mix.mq-down(xl) {
    height: auto;
  }
}

.ms2022-gnav {
  margin-bottom: 3rem !important;
}

.breadCrumb {
  @include mix.mq-up(xl) {
    margin-top: -2rem;
  }
}

#main > .h01 {
  margin-bottom: 3rem !important;
}

#main > .h02,
#main > .hd02 {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

#main > .h03,
#main > .h03noLine,
#main > .h04 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}