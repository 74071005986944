@use "~/src/scss/base/mixins" as mix;

.ms2022-feature {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 !important;
  background: none !important;

  .arrowBlock {
    background: none;
    margin: 0;
    padding: 0;
  }

  .block01In {
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0 !important;
    box-shadow: none;
    @include mix.mq-up(xl) {
      align-items: center;
      gap: 3rem;
    }
  }

  .flL {
    width: 4rem;
    @include mix.mq-up(xl) {
      width: auto;
    }
  }
  
  .flL img {
    height: auto;
  }

  .flR {
    flex: 1;
    width: auto !important;
    padding: 0;
  }
}

.ms2022-feature--mod2 {
  > .block01In {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }

  > .block01In > * {
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0 !important;
    box-shadow: none;
    @include mix.mq-up(xl) {
      align-items: center;
      gap: 3rem;
    }
  }
}