a.blank {
  background: none;
  padding: 0;

  &::after {
    content: "\f35d";
    margin-left: .5rem;
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
    font-size: .75em;
  }
}