@font-face {
  font-family: 'icomoon';
  src:  url('/src/fonts/icomoon.eot?g7rlnm');
  src:  url('/src/fonts/icomoon.eot?g7rlnm#iefix') format('embedded-opentype'),
    url('/src/fonts/icomoon.ttf?g7rlnm') format('truetype'),
    url('/src/fonts/icomoon.woff?g7rlnm') format('woff'),
    url('/src/fonts/icomoon.svg?g7rlnm#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cashless:before {
  font-size: 1.2em;
}

.icon-apply:before {
  content: "\e906";
}
.icon-arrow:before {
  content: "\e903";
}
.icon-cashless:before {
  content: "\e921";
}
.icon-confab:before {
  content: "\e908";
}
.icon-contact:before {
  content: "\e90c";
}
.icon-cservice:before {
  content: "\e938";
}
.icon-deposit:before {
  content: "\e905";
}
.icon-kinri:before {
  content: "\e90d";
}
.icon-loan:before {
  content: "\e904";
}
.icon-location:before {
  content: "\e900";
}
.icon-prepare:before {
  content: "\e907";
}
.icon-question:before {
  content: "\e902";
}
.icon-question2:before {
  content: "\e90f";
}
.icon-save:before {
  content: "\e90e";
}
.icon-search:before {
  content: "\e901";
}
.icon-service:before {
  content: "\e909";
}
.icon-simulation:before {
  content: "\e90a";
}
.icon-tesuryo:before {
  content: "\e90b";
}
.icon-product-car:before {
  content: "\e911";
}
.icon-product-card-loan:before {
  content: "\e910";
}
.icon-product-education:before {
  content: "\e912";
}
.icon-product-education2:before {
  content: "\e913";
}
.icon-product-free-loan:before {
  content: "\e914";
}
.icon-product-free-loan2:before {
  content: "\e915";
}
.icon-product-free-loan3:before {
  content: "\e917";
}
.icon-product-housing:before {
  content: "\e916";
}
.icon-product-housing2:before {
  content: "\e918";
}
.icon-product-housing3:before {
  content: "\e91a";
}
.icon-product-insurance:before {
  content: "\e919";
}
.icon-product-insurance2:before {
  content: "\e91b";
}
.icon-product-insurance3:before {
  content: "\e91c";
}
.icon-product-insurance4:before {
  content: "\e92f";
}
.icon-product-insurance5:before {
  content: "\e91d";
}
.icon-product-insurance6:before {
  content: "\e91e";
}
.icon-product-loan:before {
  content: "\e91f";
}
.icon-product-loan2:before {
  content: "\e937";
}
.icon-product-loan3:before {
  content: "\e920";
}
.icon-product-loan4:before {
  content: "\e922";
}
.icon-product-loan5:before {
  content: "\e930";
}
.icon-product-loan6:before {
  content: "\e931";
}
.icon-product-loan7:before {
  content: "\e933";
}
.icon-product-loan8:before {
  content: "\e932";
}
.icon-product-other:before {
  content: "\e923";
}
.icon-product-other10:before {
  content: "\e92c";
}
.icon-product-other11:before {
  content: "\e934";
}
.icon-product-other12:before {
  content: "\e935";
}
.icon-product-other13:before {
  content: "\e936";
}
.icon-product-other2:before {
  content: "\e924";
}
.icon-product-other3:before {
  content: "\e925";
}
.icon-product-other4:before {
  content: "\e926";
}
.icon-product-other5:before {
  content: "\e927";
}
.icon-product-other6:before {
  content: "\e928";
}
.icon-product-other7:before {
  content: "\e929";
}
.icon-product-other8:before {
  content: "\e92a";
}
.icon-product-other9:before {
  content: "\e92b";
}
.icon-product-welfare:before {
  content: "\e92d";
}
.icon-product-welfare2:before {
  content: "\e92e";
}
