@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.columnArea {
  &.ms2022-contact {
    padding: 0;
    border: none;
  }

  + .ms2022-contact {
    margin-top: 1rem;
  }

  &.ms2022-contact .inquiryArea {
    display: grid;
    gap: 2rem;
    @include mix.mq-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  &.ms2022-contact .inquiryArea > * {
    width: 100% !important;
  }

  .ms2022-call-center {
    @include mix.mq-up(xl) {
      grid-column: span 2;
    }
  }
}

.ms2022-search-store {
  padding: 1rem;
  border: 1px solid #ccc;
  text-decoration: none;

  &:hover {
    opacity: .7;
  }

  &__icon {
    min-height: auto;
    margin: 0 auto 1rem;
    padding: 0;
    border: none;
    color: var(--primary-color);
    text-align: center;
  }
  
  &__icon > i {
    font-size: 5rem;
  }

  img {
    position: static;
  }

  p:nth-child(2) {
    margin-bottom: 0 !important;
  }
  
  strong {
    display: flex;
    align-items: center;
    min-height: auto;
    padding: 0;
    border: none;
    color: var(--primary-color);
    font-size: 20px;
  }

  strong::before {
    content: "";
    width: .5rem;
    height: .5rem;
    margin-right: 1rem;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
  }

  a {
    text-decoration: none;
  }
}

.ms2022-call-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  border: 1px solid #ccc;
  text-decoration: none;

  &:hover {
    opacity: .7;
  }

  p {
    color: black;
  }

  p:first-child {
    margin-bottom: 1rem;
    color: var(--primary-color);
    font-size: 20px;
    font-weight: bold;
  }

  p:last-child {
    margin-top: .5rem;
  }
  
  &__tel,
  &__fax {
    line-height: 1.3;
    font-weight: bold;
  }
  
  &__tel span {
    color: red;
    font-size: 1.5rem !important;
    @include mix.mq-up(xl) {
      font-size: 2rem !important;
    }
  }
  
  a[href^="tel:"] {
    color: red;
  }

  &__fax span {
    font-size: 1.25rem !important;
    @include mix.mq-up(xl) {
      font-size: 1.5rem !important;
    }
  }
}