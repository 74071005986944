@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.anchorList {
  display: grid;
  gap: 2rem;
  @include mix.mq-up(xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  > li {
    margin: 0;
    padding: 0;
    background: none;
  }
  
  a {
    @include mix.button;
    height: 100%;
    text-decoration: none;
  }
}