@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

footer {
  display: block;
  margin-top: 4rem;
  margin-bottom: 1rem;
  padding-top: 0;
  border-bottom: 4px solid var(--primary-color);
  background: none;
  font-size: 1rem;

  .inner {
    width: 100%;
    padding: 0 1rem;
  }

  .inner .leftArea {
    float: none;
    width: 100%;
  }
  
  .inner .leftArea .topBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    text-align: center;
  }

  .inner .leftArea > .cf:not(.topBlock) {
    display: none;
  }

  .inner .leftArea .topBlock p.logo {
    display: block;
    width: 60%;
    max-width: 15rem;
    margin-bottom: 1rem;
    background: none;
  }

  .inner .leftArea .topBlock p.logo img {
    height: auto;
  }

  .inner .leftArea .topBlock p.txt {
    float: none;
    width: 100%;
    color: black;
  }

  > .ms2022-sitemap-index {
    margin-bottom: 2rem;
  }
}

.copy {
  width: 100%;
  margin: 2rem 0 4rem;
  text-align: center;
  color: var(--primary-color);
  @include mix.mq-up(xl) {
    margin-bottom: 2rem;
  }
}