@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-personal-loan-simulation {
  .btnWrap {
    position: relative;
    background: none;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: calc(50% - .75rem);
      width: 1.5rem;
      height: 1.5rem;
      border-top: 4px solid var.$primary;
      border-right: 4px solid var.$primary;
      transform: rotate(135deg);
    }
  }
  
  .btnColor {
    &::after {
      display: none;
    }

    > a {
      padding: 0;
    }
  }

  .specialBox {
    background: var.$secondary;
  }

  .specialBoxIn {
    background: none;
  }

  .itemTable {
    background: white;

    .bgColorG {
      background-color: #eee !important;
    }

    th {
      @include mix.mq-down(xl) {
        width: 100%;
      }
    }
  }
}