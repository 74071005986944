@use "~/src/scss/base/variables" as var;

%cat {
  display: inline-block;
  padding: 3px 1rem 0;
  border-radius: .25rem;
  color: white;
  font-size: .825rem;
}

.ms2022-news-list {
  &__item {
    padding: 1rem;
    border-bottom: 1px solid #ccc;
  }

  &__updated {
    display: inline-block;
    margin-right: 1rem;
  }
  

  &__cat--info {
    @extend %cat;
    background-color: #1D2087;
  }
  
  &__cat--important {
    @extend %cat;
    background-color: red;
  }

  &__cat--company {
    @extend %cat;
    background-color: rgb(110, 166, 0);
  }

  &__cat--corporation {
    @extend %cat;
    background-color: rgb(7, 146, 67);
  }

  &__cat--product {
    @extend %cat;
    background-color: rgb(2, 157, 183);
  }

  &__cat--personal {
    @extend %cat;
    background-color: rgb(255, 128, 1);
  }

  &__cat--other {
    @extend %cat;
    background-color: gray;
  }

  &__title {
    display: block;
    margin-top: .5rem;
    color: var.$primary;
  }

  &__more {
    margin-top: 1rem;
    text-align: right;
  }

  &--info {
    .ms2022-news-list__title {
      color: black;
    }
  }


  &--important {
    .ms2022-news-list__title {
      color: red;
    }
  }
}