.ms2022-caution {
  padding: 2rem 0;
  color: red;
  font-size: 1rem;
  background: #F2F2F2;

  > .ms2022-container {
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 1rem;
    font-size: 1.5em;
  }
  
  a {
    color: red !important;
  }
}