@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

:root {
  --gnav-bar-height: 4rem
}

%gnav {
  position: absolute;
  top: 84px;
  left: 0;
  z-index: 40;
  // overflow-y: scroll;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: white;
  border-bottom: 3px solid var(--primary-color);
  transform: translateY(-100%);
  transition: transform .5s ease;
  @include mix.mq-up(xl) {
    position: relative;
    top: 0;
    z-index: 10;
    // overflow: visible;
    max-width: none;
    height: auto;
    transform: none;
    border-bottom: none;
  }
}

#gnavi.ms2022-gnav {
  @extend %gnav;
  
  &--other {
    @extend %gnav;
    border-bottom: 4px solid var(--primary-color);
  }

  li {
    float: none;
    padding: 0;
  }

  span {
    display: block;
  }
  
  &.is-show {
    transform: translateY(0);
    // box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }

  &::before {
    @include mix.mq-up(xl) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 3rem;
      background-color: white;
    }
  }

  &::after {
    @include mix.mq-up(xl) {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: var(--gnav-bar-height);
      background-color: var(--primary-color);
    }
  }

  .ms2022-nav-buttons {
    justify-content: center;
    gap: 1.5rem;
    padding: .5rem 1rem;
    background-color: var(--secondary-color);
    @include mix.mq-up(xl) {
      display: none;
    }
  }

  .ms2022-nav-buttons__item {
    display: flex;
    flex-direction: row;
    gap: .5rem;
  }
  
  ul {
    width: 100%;
    @include mix.mq-up(xl) {
      display: flex;
      width: map-get(var.$breakpoints, xl);
      margin: auto;
      padding-bottom: var(--gnav-bar-height);
    }
  }
  
  ul > li {
    float: none;
    width: 100%;
    padding: 0;
    background-color: white;
    border-bottom: 1px solid var(--primary-color);
    font-size: 1rem;
    @include mix.mq-up(xl) {
      position: relative;
      border-bottom: none;
    }
  }

  ul > li:not(:last-child)::after {
    @include mix.mq-up(xl) {
      content: "";
      position: absolute;
      top: 20%;
      z-index: 10;
      right: -1px;
      width: 1px;
      height: 60%;
      background: var(--primary-color);
    }
  }
  
  ul > li.is-current {
    background-color: var(--primary-color);
    border-bottom: none;
    color: white;
    @include mix.mq-up(xl) {
      z-index: 10;
      margin-left: -1px;
    }
  }

  ul > li.is-current::after {
    display: none;
  }

  ul > li:not(.is-current) .ms2022-gnav-category {
    display: none;
  }

  ul > li > a {
    width: auto;
    height: 3rem;
    padding: 0 1rem;
    border-bottom: 1px solid white;
    background: none;
    color: inherit;
    font-weight: bold;
    text-decoration: none;
    line-height: 3rem;
    @include mix.mq-up(xl) {
      border: none;
      text-align: center;
      font-size: 1.25rem;
    }
  }

  .ms2022-gnav__sub-category {
    @include mix.mq-up(xl) {
      position: absolute;
      top: 3rem;
      left: 0;
      display: flex;
      width: 100%;
      padding: 0 calc((100vw - map-get(var.$breakpoints, xl) - 14px) / 2);
    }
  }

  .ms2022-gnav__sub-category > * {
    @include mix.mq-up(xl) {
      flex: 1;
    }
  }

  .searchArea {
    width: 100%;
    padding: 1rem !important;
    background-color: white;
    transition: opacity .2s;
    @include mix.mq-up(xl) {
      display: none;
    }
  }
  
  .searchArea.is-show {
    opacity: 1;
    visibility: visible;
  }

  .searchArea form {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .searchArea form[action="/cgi-bin/_search/search.cgi"] {
    display: none;
  }
  
  .searchArea input[type=text] {
    flex: 1;
    width: auto !important;
    height: 2.5rem;
    padding: .5rem;
    border: 1px solid var(--primary-color);
    border-radius: 0;
    font-size: 1rem;
  }

  .searchArea input[type=text]:focus {
    outline: none;
  }

  .searchArea input[type=submit] {
    display: none;
  }

  .searchArea button {
    min-width: 2.5rem;
    height: 40px;
    margin: 0;
    border: none;
    background: none;
    background-color: var(--primary-color);
    color: white;
    outline: none;
    appearance: initial;
  }

  .searchArea button > i {
    font-size: 1rem;
  }
}