@use "~/src/scss/base/variables" as var;
@use "~/src/scss/base/mixins" as mix;

.ms2022-banking-app {
  img {
    max-width: 100%;
  }
  
  &__inner {
    display: flex;
    flex-direction: column-reverse;
    padding: 2rem 1rem 0;
    background: var.$secondary;
    @include mix.mq-up(sm) {
      flex-direction: row;
      justify-content: center;
      padding: 1rem 1rem 0;
    }
  }

  &__screenshot {
    width: 50%;
    max-width: 12rem;
    margin: 2rem auto 0;
    @include mix.mq-up(sm) {
      width: 16rem;
      max-width: none;
      margin: -2rem 2rem 0 0;
    }
  }

  &__desc {
    text-align: center;
  }

  &__headline {
    margin-bottom: .5rem;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.2;
  }

  &__headline > span {
    display: block;
    font-size: .875em;
  }
  
  &__text {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  &__store-btns {
    display: flex;
    justify-content: center;
    gap: 1rem;
    max-width: 20rem;
    margin: 0 auto 1rem;
    @include mix.mq-up(sm) {
      width: 15rem;
    }
  }

  &__store {
    flex: 1;
  }
  
  &__store img {
    width: 100%;
  }

  &__store:hover img {
    opacity: 1;
  }
  
  &__more {
    color: var.$primary;
  }
}