.disc {
  margin-left: 0 !important;
  padding-left: 17px;
  
  li {
    background: none;
    margin: 0;
    margin-bottom: .5rem;
    padding: 0;
    list-style: disc !important;
  }
}

.note {
  li {
    margin: 0;
    margin-bottom: .5rem;
    padding: 0;
    text-indent: 0;
  }
}

.number {
  padding-left: 30px;
}