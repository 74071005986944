@use "~/src/scss/base/mixins" as mix;
@use "~/src/scss/base/variables" as var;

[class^="ms2022-personal-pservice-direct"] {
  .block01.nav {
    padding: 1rem;
    
    .linkList {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      padding-left: 0;
    }

    li {
      margin-bottom: 0;
    }

    span {
      margin-right: 0;
      padding-right: 0;
      background: none;
    }
  }

  .btnCenter.w600 {
    width: 100% !important;
    @include mix.mq-up(xl) {
      width: 50% !important;
    }
  }
}

.ms2022-personal-pservice-direct-information {
  background: none;
  
  .payeasy {
    padding-right: 0;
    background: none;

    &::before {
      content: "";
      display: block;
      width: 87px;
      height: calc(87px * 0.74);
      margin-bottom: 1rem;
      background: url(/common/image/pay-easy.png) no-repeat right 0;
    }
  }
}

.ms2022-personal-pservice-direct-howto {
  .block01 {
    padding: 0;
    background: none;
  }
}

.ms2022-personal-pservice-direct-faq {
  .block01.qa {
    padding: 0;
    background-color: transparent !important;
  }

  .questionList {
    li {
      padding: 0;
      background: none;
      list-style: disc;
    }
  }

  .h03 > a {
    color: var.$primary;
  }
}

.ms2022-personal-pservice-direct-logon {
  margin-top: 3rem !important;

  #main {
    > h2 {
      display: none;
    }

    > .btnColor {
      @include mix.mq-up(xl) {
        width: 50%;
        margin: auto;
      }
    }
  }
  
  .securityArea {
    > .hd02 {
      margin-bottom: 2rem;
    }
    
    .linkList {
      margin-left: 0;
    }
  }

  .box01container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0;
    background-color: transparent !important;
    @include mix.mq-up(xl) {
      flex-direction: row;
    }
    
    .fs18 {
      text-align: left !important;
      @include mix.mq-up(xl) {
        width: 100%;
      }
    }

    .box01 {
      display: block;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 0;
      box-shadow: none;
      @include mix.mq-up(xl) {
        display: inline-block;
        width: auto;
        flex: 1;
      }
    }
  }
}

.ms2022-personal-pservice-direct-info04 {
  .h04 {
    margin-left: 0;
  }
}

.ms2022-personal-pservice-toto {
  table {
    td {
      display: inline-block;
      @include mix.mq-up(xl) {
        display: table-cell;
      }
    }
  }
}