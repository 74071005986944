@use "~/src/scss/base/mixins" as mix;
@use "./variables" as local-var;

.ms2022-personal-loan-mitoshin_cash {
  --lp-theme-color-primary: #{map-get(local-var.$castle, "primary")};
  --lp-theme-color-primary-light: #{lighten(map-get(local-var.$castle, "primary"), 10%)};
  --lp-theme-color-secondary: #{map-get(local-var.$castle, "secondary")};
  --lp-theme-color-tertiary: #{map-get(local-var.$castle, "tertiary")};
  --lp-theme-color-accent: #{local-var.$accent};
  --lp-theme-color-accent-light: #{lighten(local-var.$accent, 10%)};

  .ms2022-lp-heading {
    color: var(--lp-theme-color-tertiary);
  }
}

.ms2022-lp-castle-header {
  overflow-x: hidden;
  margin: 0 -1rem;
  @include mix.mq-up(xl) {
    overflow-x: visible;
  }

  &__container-fluid {
    width: 100vw;
    margin-left: calc(50% - 50vw);
    @include mix.mq-up(xl) {
      width: calc(100vw - 15px);
      margin-left: calc(50% - 50vw + (15px / 2));
    }
  }
  
  &__signboard {
    position: relative;
    margin-bottom: 1rem;
  }

  &__box {
    padding-top: 1rem;
    padding-bottom: 1rem;
    @include mix.mq-up(sm) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &__box:first-child {
    background: linear-gradient(to right, #00C8D7 0%, #19A8BF 100%);
    @include mix.mq-up(xl) {
      background: linear-gradient(to right, #00C8D7 0%, #19A8BF 50%);
    }
  }

  &__box:nth-of-type(2) {
    background: linear-gradient(to right, #298aa4 0%, #236f8e 100%);
  }

  &__box > * {
    position: relative;
    z-index: 1;
  }

  &__container {
    @include mix.mq-up(xl) {
      max-width: calc(1200px + 2rem);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__heading {
    width: 60%;
    margin-bottom: 1rem;
    @include mix.mq-up(xl) {
      max-width: 43rem;
      margin-bottom: 2rem;
    }
  }

  &__features {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 1rem;
    @include mix.mq-up(xl) {
      max-width: 43rem;
      flex-direction: row;
      gap: 1.5rem;
      font-size: 1.5rem;
    }
  }

  &__features > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: 2rem;
    padding: 0 1rem;
    border-radius: 99px;
    background-color: var(--lp-theme-color-primary);
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("DB");
    text-align: center;
    @include mix.mq-up(xl) {
      height: 3rem;
    }
  }
  
  &__features > li > span {
    font-size: .5em;
  }

  &__text {
    color: white;
    @include mix.m-plus-1p("M");
    font-size: 1.25rem;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 2.75rem;
    }
  }

  &__text > br {
    @include mix.mq-up(xl) {
      display: none;
    }
  }
  
  &__limit {
    position: relative;
    z-index: 1;
    margin-bottom: 1rem;
    @include mix.mq-up(xl) {
      margin-bottom: 2rem;
    }
  }

  &__limit > p {
    color: white;
    @include mix.m-plus-1p("M");
    font-size: .75rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__limit .ms2022-lp-kinri__value {
    color: var(--lp-theme-color-primary);
    font-size: 4.5rem;
    @include mix.mq-up(xl) {
      font-size: 8rem;
    }
  }

  .ms2022-lp-castle-rate {
    position: relative;
    z-index: 1;
  }

  &__image {
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__image > img {
    object-fit: contain;
    object-position: right;
    width: 100%;
    height: 100%;
    @include mix.mq-up(xl) {
      object-fit: cover;
    }
  }

  &__notice {
    color: var(--lp-theme-color-accent);
    font-size: .875rem;
    @include mix.m-plus-1p("DB");
    text-decoration: underline;
    @include mix.mq-up(xl) {
      font-size: 1rem;
    }
  }
}

.ms2022-lp-castle-rate {
  > p {
    color: white;
    @include mix.m-plus-1p("M");
    font-size: .75rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__value {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
  }

  &__leading {
    color: white;
    font-size: 2rem;
    line-height: 1;
    @include mix.mq-up(xl) {
      font-size: 3rem;
    }
  }

  &__max {
    color: var(--lp-theme-color-primary);
    font-size: 4rem;
    @include mix.mq-up(xl) {
      font-size: 8rem;
    }
  }

  &__min {
    color: white;
    font-size: 2rem !important;
    @include mix.mq-up(xl) {
      font-size: 3rem !important;
    }
  }

  &__year {
    @include mix.mq-up(xl) {
      font-size: 2rem;
    }
  }

  &__min > .ms2022-lp-castle-rate__year {
    @include mix.mq-up(xl) {
      font-size: .8em !important;
    }
  }
}

.ms2022-lp-castle-value {
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: var(--lp-theme-color-secondary);
  @include mix.mq-up(xl) {
    margin-bottom: 3rem;
    padding: 2rem;
  }

  &__inner {
    display: grid;
    gap: 2rem;
    padding: 1rem;
    background-color: white;
    @include mix.mq-up(xl) {
      grid-template-columns: 1fr 1fr;
      gap: 3rem;
      padding: 2rem;
    }
  }

  &__heading {
    height: 2rem;
    margin-bottom: 1.5rem;
    background-color: var(--lp-theme-color-primary);
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("DB");
    font-size: 1.125rem;
    text-align: center;
    line-height: 2rem;
    @include mix.mq-up(xl) {
      height: 3rem;
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  &__limit {
    justify-content: center;
    margin-left: auto !important;
    margin-right: auto;
    color: var(--lp-theme-color-accent);
    font-size: 4.5rem;
    @include mix.mq-up(xl) {
      font-size: 8rem;
    }
  }

  &__limit > span:last-child {
    margin-bottom: 0;
  }

  &__rate {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 1rem;
    margin-top: auto;
    @include mix.mq-up(xl) {
      margin-top: 1%;
    }
  }

  &__leading {
    color: var(--lp-theme-color-tertiary);
    font-size: 2rem;
    line-height: 1;
    @include mix.mq-up(xl) {
      font-size: 3rem;
    }
  }

  &__max-rate {
    color: var(--lp-theme-color-accent);
    font-size: 4rem;
    @include mix.mq-up(xl) {
      font-size: 8rem;
    }
  }

  &__min-rate {
    color: var(--lp-theme-color-tertiary);
    font-size: 2rem !important;
    @include mix.mq-up(xl) {
      font-size: 3rem !important;
    }
  }

  &__min-rate > span:first-child {
    @include mix.mq-up(xl) {
      font-size: 2rem;
    }
  }
}

.ms2022-lp-castle-accepting {
  display: none;
  @include mix.mq-up(xl) {
    display: block;
  }

  &__text {
    color: var(--lp-theme-color-accent);
    font-size: 2rem;
    @include mix.m-plus-1p("B");
    text-align: center;
  }

  &__button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30rem;
    height: 5rem;
    margin: auto;
    padding: .25rem 1rem;
    border: none;
    border-radius: .5rem;
    background-color: var(--lp-theme-color-accent);
    color: white !important;
    @include mix.m-plus-1p("DB");
    font-size: 2rem;
    text-align: center;
    text-decoration: none;
    line-height: 1.3;
    cursor: pointer;
    transition: background .2s;
  }

  &__button:hover {
    background-color: var(--lp-theme-color-accent-light);
  }

  &__button.is-disabled {
    background-color: var(--lp-theme-color-primary);
  }
}

.ms2022-lp-castle-feature {
  .ms2022-lp-feature__heading {
    color: var(--lp-theme-color-tertiary);
  }
}

.ms2022-lp-castle-five {
  .ms2022-lp-points__heading {
    font-size: 2.5rem;
  }

  .ms2022-lp-points__heading br {
    display: none;
    @include mix.mq-up(sm) {
      display: block;
    }
    @include mix.mq-up(xl) {
      display: none;
    }
  }
  
  &__hidden {
    display: none;
  }

  .ms2022-lp-points__group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
    @include mix.mq-up(sm) {
      gap: 3rem;
      grid-template-columns: repeat(3, 1fr);
    }
    @include mix.mq-up(xl) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ms2022-lp-circle {
    width: 100%;
    @include mix.mq-up(xl) {
      width: 200px;
    }
  }

  .ms2022-lp-circle__icon {
    @include mix.mq-up(xl) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @at-root {
    _::-webkit-full-page-media, _:future, :root [class^="ms2022"] .ms2022-lp-castle-five .ms2022-lp-circle__icon {
      @include mix.mq-up(xl) {
        height: 103px;
      }
    }
  }

  .ms2022-lp-points__subject {
    font-size: 1rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  .ms2022-lp-points__group > div:first-child img,
  .ms2022-lp-points__group > div:nth-child(2) img {
    height: 80%;
  }
  
  .ms2022-lp-points__group > div:nth-child(5) img {
    height: 70%;
  }

  .ms2022-lp-points__group > div:nth-child(6) img {
    height: 90%;
  }
}

.ms2022-lp-castle-points {
  display: grid;
  gap: 2rem;
  padding: 1rem;
  background-color: var(--lp-theme-color-secondary);
  @include mix.mq-up(xl) {
    gap: 3rem;
    padding: 3rem;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("DB");
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.3;
    @include mix.mq-up(xl) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      gap: 1rem;
      font-size: 2rem;
    }
  }

  &__heading > span > span {
    font-size: 1.5em;
    line-height: 1;
  }

  &__heading br {
    @include mix.mq-up(xl) {
      display: none;
    }
  }

  &__block {
    padding: 1rem;
    background-color: white;
    @include mix.mq-up(xl) {
      padding: 3rem;
    }
  }

  .ms2022-lp-step {
    padding: 0;
    border: none;
  }

  .ms2022-lp-step__heading > span {
    color: var(--lp-theme-color-accent);
  }

  .ms2022-lp-step__circle {
    background-color: var(--lp-theme-color-tertiary);
  }

  &__subhead {
    display: table;
    width: 100%;
    margin: 0 auto 1rem;
    padding: .5rem 1rem;
    border-radius: .75rem;
    background-color: var(--lp-theme-color-primary);
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("M");
    font-size: 1.125rem;
    font-feature-settings: "palt";
    text-align: center;
    line-height: 1.2;
    @include mix.mq-up(xl) {
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }
  }

  &__table > table {
    width: 100%;
    border-collapse: collapse;
    @include mix.m-plus-1p("M");
    font-size: .875rem;
    font-feature-settings: "palt";
    @include mix.mq-up(xl) {
      font-size: 1.25rem;
    }
  }

  &__table th,
  &__table td {
    padding: 4px;
    border: 1px solid #569DD6;
    text-align: center;
    @include mix.mq-up(xl) {
      padding: .5rem 1rem;
    }
  }
  
  &__table th:first-child,
  &__table td:first-child {
    border-left: none;
  }

  &__table th:last-child,
  &__table td:last-child {
    border-right: none;
  }

  &__table th:nth-last-of-type(2),
  &__table td:nth-last-of-type(2):not([rowspan]) {
    background-color: #B2CFE6;
  }

  &__table:first-child td:nth-last-of-type(2):not([rowspan]),
  &__table:first-child td:last-child {
    text-align: right;
  }

  &__button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 3rem;
    margin: 2rem auto 0;
    padding: .25rem 1rem;
    border: none;
    border-radius: .5rem;
    background-color: var(--lp-theme-color-accent);
    color: white !important;
    @include mix.m-plus-1p("DB");
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    line-height: 1.3;
    cursor: pointer;
    transition: background .2s;
    @include mix.mq-up(xl) {
      width: 30rem;
      height: 5rem;
      margin-top: 3rem;
      font-size: 2rem;
    }
  }

  &__button:hover {
    background-color: var(--lp-theme-color-accent-light);
  }

  &__button.is-disabled {
    background-color: var(--lp-theme-color-primary);
  }

  &__desc {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem auto;
    @include mix.m-plus-1p("M");
    line-height: 1.3;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__desc > *:first-child {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  &__desc b {
    color: var(--lp-theme-color-accent);
    @include mix.m-plus-1p("DB");
    font-size: 1.5em;
  }

  &__desc .ms2022-lp-list--asterisk {
    font-size: .75rem;
    @include mix.m-plus-1p("R");
    @include mix.mq-up(xl) {
      font-size: 1rem;
    }
  }
}

.ms2022-lp-castle-simulation {
  display: grid;
  gap: 2rem;
  @include mix.mq-up(xl) {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
  
  &__heading {
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    border-top: 1px solid var(--lp-theme-color-tertiary);
    border-bottom: 1px solid var(--lp-theme-color-tertiary);
    color: var(--lp-theme-color-tertiary);
    text-align: center;
    @include mix.m-plus-1p("DB");
    font-size: 1.125rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  .ms2022-lp-castle-points__table span {
    color: var(--lp-theme-color-accent);
    font-size: .9em;
  }

  &__calculation {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 1rem;
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__calculation b {
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("B");
  }

  &__calculation > p:nth-child(1) {
    font-size: .875em;
  }

  &__calculation > p:nth-child(2) {
    position: relative;
    margin-bottom: 1rem;
    padding-bottom: .5rem;
    font-size: 1em;
  }

  &__calculation > p:nth-child(2)::before {
    content: "";
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 0;
    height: 1px;
    background-color: var(--lp-theme-color-tertiary);
  }

  &__calculation > p:nth-child(2)::after {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    margin: 0 auto calc((1rem + 1px) * -1);
    border-left: 1px solid var(--lp-theme-color-tertiary);
    border-bottom: 1px solid var(--lp-theme-color-tertiary);
    background-color: white;
    transform: rotate(-45deg);
  }

  &__calculation > p:nth-child(3) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__calculation > p:nth-child(3) > b {
    margin-bottom: .05em;
    font-size: 1.5em;
    line-height: 1;
  }

  &__calculation > p:nth-child(3) > b + span {
    color: var(--lp-theme-color-tertiary);
  }

  &__borrowing-period {
    border: 1px solid var(--lp-theme-color-tertiary);
    text-align: center;
  }

  &__borrowing-period + .ms2022-lp-castle-simulation__borrowing-period {
    margin-top: 1rem;
  }

  &__borrowing-period > h4 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 3px;
    background-color: var(--lp-theme-color-tertiary);
    color: white;
    @include mix.m-plus-1p("M");
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__borrowing-period > h4 > span {
    margin-bottom: .1em;
    font-size: 1.2em;
    line-height: 1;
  }

  &__borrowing-period > p {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: .5rem;
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("M");
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__borrowing-period > p > span {
    display: inline-block;
    margin: 0 0 1px .5rem;
    @include mix.m-plus-1p("B");
    font-size: 1.5em;
    line-height: 1;
  }
}

.ms2022-lp-castle-usecase {
  display: grid;
  gap: 2rem;
  @include mix.mq-up(xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__card {
    @include mix.mq-up(xl) {
      display: flex;
      flex-direction: column;
    }
  }

  &__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background-color: var(--lp-theme-color-tertiary);
    color: white;
    @include mix.m-plus-1p("DB");
    font-size: 1.25rem;
    text-align: center;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__image {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  &__caption {
    text-align: center;
  }
}

.ms2022-lp-castle-atm {
  display: grid;
  grid-template-columns: 7rem 1fr;
  gap: 1rem;
  @include mix.mq-up(xl) {
    grid-template-columns: 1fr 1fr;
  }

  &__image {
    grid-column: 1;
    margin: -1.5rem 0 0 -.5rem;
    @include mix.mq-up(xl) {
      grid-area: 2 / 1;
      width: 22rem;
      margin: -2rem auto 0;
    }
  }

  &__image > img {
    width: 100%;
  }

  &__text {
    grid-column: 2;
    align-self: center;
    @include mix.m-plus-1p("DB");
    @include mix.mq-up(xl) {
      grid-area: 1 / span 2;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  &__text br:not(:last-child) {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  &__text b {
    color: var(--lp-theme-color-accent);
    font-size: 1.2em;
  }

  &__available {
    grid-area: 2 / span 2;
    padding: 1rem;
    border: 2px solid var(--lp-theme-color-tertiary);
    border-radius: .5rem;
    @include mix.mq-up(xl) {
      grid-area: 2 / 2;
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  &__available > h3 {
    margin-bottom: 1rem;
    color: var(--lp-theme-color-tertiary);
    @include mix.m-plus-1p("DB");
    text-align: center;
    font-size: 1.25rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  &__group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
  }

  img[src*="point4-brand3.svg"] {
    width: 50%;
    max-width: 6rem;
    @include mix.mq-up(xl) {
      max-width: 8rem;
    }
  }

  &__notices {
    margin-top: 1rem;
    font-size: .75rem;
  }
}

.ms2022-lp-castle-nofees {
  margin-bottom: 1rem;
  
  &__box {
    margin-bottom: .5rem;
    border: 2px solid var(--lp-theme-color-tertiary);
  }

  &__heading {
    background-color: var(--lp-theme-color-tertiary);
    color: white;
    text-align: center;
    font-size: 1.125rem;
  }

  &__brands {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: .5rem 1rem;
  }

  &__brands > img[src*="point4-brand1.svg"] {
    width: 60%;
    max-width: 13rem;
    @include mix.mq-up(xl) {
      width: 10rem;
    }
  }
  
  &__brands > img[src*="point4-brand2.svg"] {
    width: 40%;
    max-width: 7rem;
    @include mix.mq-up(xl) {
      width: 8rem;
    }
  }

  &__timebelt {
    display: flex;
    border-top: 1px solid #337EBD;
    text-align: center;
  }
  
  &__timebelt > h4 {
    display: flex;
    align-items: center;
    padding: .25rem;
    background-color: #337EBD;
    color: white;
    font-size: .875rem;
    line-height: 1.2;
    @include mix.mq-up(xl) {
      font-size: 1rem;
    }
  }

  &__timezone {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: .75rem;
    @include mix.mq-up(xl) {
      font-size: 1rem;
    }
  }

  &__timezone:first-of-type {
    border-right: 1px solid #337EBD;
  }
  
  &__timezone > p {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 .25rem;
  }

  &__timezone > p:first-child {
    background-color: #CCDFEE;
    color: var(--lp-theme-color-tertiary);
  }

  &__notice {
    font-size: .75rem;
  }
}

.ms2022-lp-castle-repayment,
.ms2022-lp-castle-non-regular {
  @include mix.mq-up(xl) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }

  img {
    width: 100%;
  }

  .ms2022-lp-castle-points__desc {
    @include mix.mq-up(xl) {
      margin: 0;
      padding: 0;
    }
  }

  .ms2022-lp-castle-points__desc br {
    display: none;
    @include mix.mq-up(xl) {
      display: block;
    }
  }

  .ms2022-lp-list--asterisk {
    @include mix.mq-up(xl) {
      margin-top: auto;
    }
  }
}

.ms2022-lp-castle-qa {
  @include mix.mq-up(xl) {
    max-width: 1000px;
    margin: auto;
  }

  .ms2022-lp-accordion__bar {
    border-radius: .25rem;
    background-color: white;
    border-color: var(--lp-theme-color-tertiary);
    color: var(--lp-theme-color-tertiary);
    font-size: 1rem;
    @include mix.mq-up(xl) {
      font-size: 1.5rem;
    }
  }

  .ms2022-lp-accordion__bar.is-open {
    border-radius: .25rem .25rem 0 0;
  }

  .ms2022-lp-accordion__body {
    border-color: var(--lp-theme-color-tertiary);
  }

  .ms2022-lp-accordion__bar > svg {
    fill: var(--lp-theme-color-tertiary);
  }

  &__list {
    display: grid;
    gap: 2rem;
  }
}

// .ms2022-lp-castle-usage {
//   @include mix.m-plus-1p("M");
//   @include mix.mq-up(xl) {
//     max-width: 1000px;
//     margin: auto;
//   }

//   &__block {
//     @include mix.mq-up(xl) {
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       gap: 3rem;
//     }
//   }

//   &__text {
//     margin-bottom: 1rem;
//     text-align: center;
//     @include mix.mq-up(xl) {
//       font-size: 1.5rem;
//       text-align: left;
//     }
//   }

//   &__image {
//     max-width: 35rem;
//     margin: 0 auto 2rem;
//     text-align: center;
//     @include mix.mq-up(xl) {
//       width: 35rem;
//       margin-bottom: 0;
//     }
//   }

//   &__example {
//     width: fit-content;
//     margin: auto;
//     padding: 1rem;
//     background-color: var(--lp-theme-color-secondary);
//     text-align: center;
//     @include mix.mq-up(xl) {
//       padding: 2rem 3rem;
//       font-size: 1.5rem;
//     }
//   }

//   &__example > p:not(:last-child) {
//     margin-bottom: .5rem;
//     @include mix.mq-up(xl) {
//       margin-bottom: 1rem;
//     }
//   }

//   &__example b {
//     color: var(--lp-theme-color-accent);
//     font-size: 1.2em;
//   }
// }

.ms2022-lp-castle-outline {
  .ms2022-lp-accordion__bar {
    background-color: var(--lp-theme-color-tertiary);
  }

  .ms2022-lp-accordion__body {
    border-color: var(--lp-theme-color-tertiary);
  }
}