@use "~/src/scss/base/mixins" as mix;

.ms2022-linkbtns {
  display: grid;
  gap: 2rem;
  @include mix.mq-up(xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  > * {
    width: 100% !important;
    margin: 0 !important;
    border: none
  }

  .clear {
    display: none;
  }

  .linkBtn {
    width: 100% !important;
    margin-bottom: 0 !important;
  }

  &--col2 {
    @include mix.mq-up(xl) {
      grid-template-columns: repeat(2, 1fr)
    }
  }
}

.linkBtn {
  background: none;
  @include mix.mq-down(xl) {
    width: 100% !important;
  }
  
  > a {
    @include mix.button;
    height: 100%;
    color: white;
  }

  > a {
    font-size: 16px;
  }

  .fs14 {
    font-size: 1em !important;
  }
}
